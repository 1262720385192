import React from "react";
import { Filters } from "../../../Common/components/Filters";
import { Space, Form, Checkbox } from "antd";
import { useFilters } from "../../hooks";
import { useForm } from "antd/lib/form/Form";
import { _t } from "../../../Common/components/InjectIntlContext";
import { setFormInputValue } from "../../../../common/helpers";
import DateRangePicker from "../../../../common/components/DateRangePicker/DateRangePicker";

export function GamersFilters({ fields = null }) {
  const [filters, updateFilters] = useFilters();
  const [form] = useForm();

  return (
    <Filters
      form={form}
      onApply={updateFilters}
      initialValues={filters}
      mainFilters={
        <Space size={22} direction="horizontal" align="center">
          <Form.Item name="date" label="Period">
            <DateRangePicker
              onChange={(dates) => {
                setFormInputValue(
                  "date",
                  {
                    from: dates[0],
                    to: dates[1],
                  },
                  form
                );
              }}
            />
          </Form.Item>
          <Form.Item name="rDate" label="Reg">
            <DateRangePicker
              onChange={(dates) => {
                setFormInputValue(
                  "rDate",
                  {
                    from: dates[0],
                    to: dates[1],
                  },
                  form
                );
              }}
            />
          </Form.Item>

          {fields}
        </Space>
      }
      expandableFilters={
        <Space size={22} direction="horizontal" align="center">
          <Form.Item name="non_empty" valuePropName="checked">
            <Checkbox>{_t("Only with statistics")}</Checkbox>
          </Form.Item>
        </Space>
      }
    />
  );
}
