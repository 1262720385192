import prodConfig from "./prod";
import stageConfig from "./stage";

const CONFIG_ENV = process.env.REACT_APP_CONFIG_ENV || "";
const config = {
  projectImages:
    "https://income-front-data.s3.fr-par.scw.cloud/goldrush/images",
  google_recaptcha_sitekey: "6LextLoUAAAAADqqnjhxQMrcuNOZhMZNkEa6d9Sg",
  bettilt_link:
    "https://bettilt.ncmtoldmego.com/en/sportsbook?partner=p2855p16012p8554#registration",
  projectCurrency: "€",
};

const reactConfigEnv = CONFIG_ENV === "stage" ? stageConfig : prodConfig;
export default { ...config, ...reactConfigEnv };
