import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./helpers/PrivateRoute";
import PageNotFound from "../modules/PageNotFound/PageNotFound";
import {
  LoginByHash,
  ForgotPassword,
  Registration,
  UserActivation,
  ResetPassword,
} from "../modules/Auth/Pages";
import User from "../modules/User/components";
import Dashboard from "../modules/Dashboard/components/index.tsx";
import Promo from "../modules/Promo/components/index";
import AllComponents from "../modules/Common/AllComponents";
import Trackers from "../modules/Trackers/pages/Trackers";
import Payments from "../modules/Payments/pages/Payments";
import Affiliates from "../modules/Affiliates/pages/Affiliates";
import Costs from "../modules/Costs/components";
import Commission from "../modules/Landing/pages/commission";
import Faq from "../modules/Faq/pages/Faq";
import LandingMain from "../modules/Landing/pages/index";
import ContactsPage from "../modules/Contacts/pages/ContactsPage";
import UsersAdmin from "../modules/Users/pages/UsersAdmin";
import { LoginById } from "../modules/Auth/Pages/LoginById";
import Login from "../modules/Auth/Pages/Login";
import { UsersProfile } from "../modules/Users/pages/UsersProfile";
import { APP_ROUTES } from "./constants/constantsUrl";
import Terms from "../modules/Landing/pages/Terms";
import PostbackOptions from "../modules/Landing/pages/PostbackOptions";
import Testimonials from "../modules/Testimonials/pages/Testimonials";
import TranslationsAdmin from "../modules/Translations/pages/TranslationsAdmin";
import TranslationsEdit from "../modules/Translations/pages/TranslationsEdit";
import NewsList from "../modules/News/pages/NewsList";
import { NewsContent } from "../modules/News/pages/NewsContent";
import TournamentsList from "../modules/Tournaments/pages/TournamentsList";
import { TournamentShow } from "../modules/Tournaments/pages/TournamentShow";
import UsersCreate from "../modules/Users/pages/UsersCreate";
import Managers from "../modules/Managers/pages/Managers";
import {
  AffiliateBrandItemEdit,
  AffiliateBrands,
  AffiliateLanding,
  AffiliateLandingCreate,
  AffiliateProgramEdit,
  AffiliateProgramCreate,
  ChatCreateForm,
  ChatInnerPage,
  ChatsListAdmin,
  ChatsListUser,
  ChatCreateFormAdmin,
  ChatInnerPageAdmin,
  GamersFTDReport,
  GamersReport,
  AffiliatesReport,
  ManageableNotificationsList,
  NotificationEdit,
  NotificationCreate,
  ManageableList,
  ManageableItemEdit,
  ManageableListAddUser,
  ManageableItemCreate,
  TestimonialList,
  TestimonialCreate,
  TestimonialEdit,
  TranslationList,
  TranslationKeyCreate,
  TranslationEdit,
  TranslationImportExcel,
  FaqList,
  FaqEdit,
  FaqCreate,
  AffiliateDifferenceReport,
  GeoReport,
  AffiliatesGeoReport,
} from "../pages/inner";
import { permissions } from "./constants/roles";
import Page404 from "../pages/error/pages/Page404";
import {
  Revshare,
  SubAffiliate,
  CPA,
} from "../modules/Landing/pages/commissions";

export default (
  <Switch>
    <Route path="/login" component={Login} />
    <Route path="/login-by-hash" component={LoginByHash} />
    <Route path={APP_ROUTES.user.restore_password} component={ForgotPassword} />
    <Route path={APP_ROUTES.user.reset_password} component={ResetPassword} />
    <Route path={APP_ROUTES.registration} component={Registration} />
    <Route path="/registration-activate" component={UserActivation} />
    <Route path="/commission/revshare" component={Revshare} />
    <Route path="/commission/cpa" component={CPA} />
    <Route path="/commission/subaffiliate" component={SubAffiliate} />
    <Route path="/commission" component={Commission} />
    <Route path="/contacts" component={ContactsPage} />
    <Route path={APP_ROUTES.terms} component={Terms} />
    <Route path={APP_ROUTES.postback_options} component={PostbackOptions} />
    <Route path={APP_ROUTES.testimonials} component={Testimonials} exact />
    <Route path={APP_ROUTES.news.list} component={NewsList} />
    <Route path={APP_ROUTES.news.show} component={NewsContent} />
    <Route path={APP_ROUTES.tournaments.list} component={TournamentsList} />
    <Route path={APP_ROUTES.tournaments.show} component={TournamentShow} />
    <PrivateRoute path="/dashboard" component={Dashboard} />
    <PrivateRoute path="/promo" component={Promo} />
    <PrivateRoute path="/trackers" component={Trackers} />
    <PrivateRoute path="/payments" component={Payments} />
    <PrivateRoute path="/affiliates" component={Affiliates} />
    <PrivateRoute
      path={APP_ROUTES.costs.main}
      component={Costs}
      permissions={permissions.dashboard.header.costs}
    />
    <PrivateRoute path="/user" component={User} />
    <PrivateRoute path="/all-components" component={AllComponents} />
    <PrivateRoute
      exact
      path={APP_ROUTES.manage.users}
      component={UsersAdmin}
      permissions={permissions.manage.users}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.manage.managers}
      component={ManageableList}
      permissions={permissions.manage.managers}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.manage.manageable_list_edit}
      component={ManageableItemEdit}
      permissions={permissions.manage.managers}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.manage.manageable_list_create}
      component={ManageableItemCreate}
      permissions={permissions.manage.managers}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.manage.manageable_list_add_user}
      component={ManageableListAddUser}
      permissions={permissions.manage.managers}
    />
    <PrivateRoute
      path={APP_ROUTES.reports.affiliates_report}
      component={AffiliatesReport}
      permissions={permissions.reports}
    />
    <PrivateRoute
      path={APP_ROUTES.reports.affiliates_geo_report}
      component={AffiliatesGeoReport}
      permissions={permissions.reports}
    />
    <PrivateRoute
      path={APP_ROUTES.reports.affiliates_difference_report}
      component={AffiliateDifferenceReport}
      permissions={permissions.reports}
    />
    <PrivateRoute
      path={APP_ROUTES.reports.gamers_ftd_report}
      component={GamersFTDReport}
      permissions={permissions.reports}
    />
    <PrivateRoute
      path={APP_ROUTES.reports.gamers_report}
      component={GamersReport}
      permissions={permissions.reports}
    />
    <PrivateRoute
      path={APP_ROUTES.reports.geo_report}
      component={GeoReport}
      permissions={permissions.reports}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.manage.users_create}
      component={UsersCreate}
      permissions={permissions.manage.users}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.manage.user_profile}
      component={UsersProfile}
      permissions={permissions.manage.users}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.login.id}
      component={LoginById}
      permissions={permissions.manage.users}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.manage.notifications.list}
      component={ManageableNotificationsList}
      permissions={permissions.manage.notifications}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.manage.notifications.create}
      component={NotificationCreate}
      permissions={permissions.manage.notifications}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.manage.notifications.edit}
      component={NotificationEdit}
      permissions={permissions.manage.notifications}
    />
    <PrivateRoute
      path={APP_ROUTES.content.translations.list}
      component={TranslationList}
      permissions={permissions.content.translation}
    />
    <PrivateRoute
      path={APP_ROUTES.content.translations.translation}
      component={TranslationEdit}
      permissions={permissions.content.translation}
    />
    <PrivateRoute
      path={APP_ROUTES.content.translations.create_key}
      component={TranslationKeyCreate}
      permissions={permissions.content.translation}
    />
    <PrivateRoute
      path={APP_ROUTES.content.translations.import_excel}
      component={TranslationImportExcel}
      permissions={permissions.content.translation}
    />
    <PrivateRoute
      path={APP_ROUTES.affiliates_programs.brands}
      exact
      component={AffiliateBrands}
      permissions={permissions.manage.brands}
    />
    <PrivateRoute
      path={APP_ROUTES.affiliates_programs.brand_edit}
      exact
      component={AffiliateBrandItemEdit}
      permissions={permissions.manage.brands}
    />
    <PrivateRoute
      path={APP_ROUTES.affiliates_programs.landing_edit}
      exact
      component={AffiliateLanding}
      permissions={permissions.manage.brands}
    />
    <PrivateRoute
      path={APP_ROUTES.affiliates_programs.landing_create}
      exact
      component={AffiliateLandingCreate}
      permissions={permissions.manage.brands}
    />
    <PrivateRoute
      path={APP_ROUTES.affiliates_programs.program_edit}
      exact
      component={AffiliateProgramEdit}
      permissions={permissions.manage.brands}
    />
    <PrivateRoute
      path={APP_ROUTES.affiliates_programs.program_create}
      exact
      component={AffiliateProgramCreate}
      permissions={permissions.manage.brands}
    />
    <PrivateRoute
      path={APP_ROUTES.chat.inner}
      exact
      component={ChatInnerPage}
    />
    <PrivateRoute
      path={APP_ROUTES.chat.create}
      exact
      component={ChatCreateForm}
    />
    <PrivateRoute path={APP_ROUTES.chat.list} exact component={ChatsListUser} />
    <PrivateRoute
      path={APP_ROUTES.chat.admin.inner}
      exact
      component={ChatInnerPageAdmin}
      permissions={permissions.chat.admin}
    />

    <PrivateRoute
      path={APP_ROUTES.chat.admin.list}
      exact
      component={ChatsListAdmin}
      permissions={permissions.chat.admin}
    />
    <PrivateRoute
      path={APP_ROUTES.chat.admin.create}
      exact
      component={ChatCreateFormAdmin}
      permissions={permissions.chat.admin}
    />
    <PrivateRoute
      path={APP_ROUTES.content.testimonials.list}
      exact
      component={TestimonialList}
      permissions={permissions.content.testimonials}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.content.testimonials.create}
      component={TestimonialCreate}
      permissions={permissions.content.notifications}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.content.testimonials.edit}
      component={TestimonialEdit}
      permissions={permissions.content.notifications}
    />
    <Route path={APP_ROUTES.faq.index} component={Faq} exact />
    <PrivateRoute
      path={APP_ROUTES.content.faq.list}
      exact
      component={FaqList}
      permissions={permissions.content.faq}
    />
    <PrivateRoute
      path={APP_ROUTES.content.faq.edit}
      exact
      component={FaqEdit}
      permissions={permissions.content.faq}
    />
    <PrivateRoute
      path={APP_ROUTES.content.faq.create}
      exact
      component={FaqCreate}
      permissions={permissions.content.faq}
    />
    <Route exact path="/" component={LandingMain} />
    <Route component={Page404} />
  </Switch>
);
