import React from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import CustomButton from "../../Common/components/CustomButton";
import { Typography } from "antd";
import HowItWorkCard from "./HowItWorkCard";
import config from "../../../system/config";

const slides = (_t: any) => [
  {
    title: _t("how_1"),
    icon: `${config.projectImages}/how-it-works/image_1.svg`,
  },
  {
    title: _t("how_2"),
    icon: `${config.projectImages}/how-it-works/image_2.svg`,
  },
  {
    title: _t("how_3"),
    icon: `${config.projectImages}/how-it-works/image_3.svg`,
  },
  {
    title: _t("how_4"),
    icon: `${config.projectImages}/how-it-works/image_4.svg`,
  },
];

export type howItWorksProps = {
  getStarted: () => void;
};

const HowItWorks: React.FC<howItWorksProps> = ({ getStarted }) => {
  const howItWorksCards = slides(_t);
  const howItWorkItem = (card, idx) => (
    <li className="how-it-work-list__item" key={card.title + idx}>
      <HowItWorkCard info={card} />
    </li>
  );

  return (
    <section className="how-it-works custom-section">
      <div className="container">
        <Typography.Title level={2} className="custom-section__title">
          {_t("how_it_works")}
        </Typography.Title>
        <ul className="how-it-work-list">
          {howItWorksCards.map(howItWorkItem)}
        </ul>
        <div className="get-started">
          <CustomButton
            onClick={getStarted}
            btnLabel={_t("get_started")}
            className=" btn--big"
          />
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
