import React from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import NavCard from "./NavCard";
import config from "../../../system/config";

const navCardsConfig = (_t: any) => [
  {
    title: _t("project_name"),
    text: _t("get_started"),
    icon: `${config.projectImages}/nav-cards/image_1.svg`,
    innerLink: APP_ROUTES.login.main,
  },
  {
    title: _t("news"),
    text: _t("get_started_news"),
    icon: `${config.projectImages}/nav-cards/image_2.svg`,
    innerLink: APP_ROUTES.news.list,
  },
  {
    title: _t("Commissions"),
    text: _t("get_started_commissions"),
    icon: `${config.projectImages}/nav-cards/image_3.svg`,
    innerLink: APP_ROUTES.commissions.commission,
  },
  {
    title: _t("contacts"),
    text: _t("get_started_contacts"),
    icon: `${config.projectImages}/nav-cards/image_4.svg`,
    innerLink: APP_ROUTES.contacts,
  },
];

export type NavCardProps = {};

const NavCards: React.FC<NavCardProps> = () => {
  const navCards = navCardsConfig(_t).map((item, idx) => (
    <li key={item.title + idx} className="nav-cards__list-item">
      <NavCard info={item} />
    </li>
  ));

  return (
    <div className="nav-cards">
      <div className="container">
        <ul className="nav-cards__list">{navCards}</ul>
      </div>
    </div>
  );
};

export default NavCards;
