import React from 'react';
import {_t} from "../../Common/components/InjectIntlContext";
import config from "../../../system/config";
import {Link} from "react-router-dom";
import {APP_ROUTES} from "../../../system/constants/constantsUrl";

const FreeCode = () => {
    return (
        <div className="container">
            <div className="free-code">
                <div className="free-code__wrap">
                    <h3 className="free-code__title">
                        {_t("free_code_title")}
                    </h3>
                    <div className="free-code__text">
                        {_t("free_code_text")}
                    </div>
                    <Link to={APP_ROUTES.registration} className="btn">
                        {_t("free_code_btn_text")}
                    </Link>
                </div>
                <div className="free-code__img">
                    <img src={`${config.projectImages}/free-code.png`} alt=""/>
                </div>
            </div>
        </div>
    );
};

export default FreeCode;