import React, {useEffect} from "react";
import LandingTemplate from "../../Layouts/LandingTemplate";
import OurBenefits from "../../Landing/components/OurBenefits";
import HowItWorks from "../../Landing/components/HowItWorks";
import Commissions from "../../Landing/components/Commissions";
import {setItem} from "../../../system/helpers/localstorageHelper";
import {INVITATION_CODE, onGetStarted} from "../constants";
import NavCards from "../components/NavCards";
import AboutSection from "../components/AboutSection";
import BenefitPoints from "../components/BenefitPoints";
import FreeCode from "../components/FreeCode";
import Partners from "../components/Partners";

const Landing = () => {
    let invitationCode = new URL(window.location.href).searchParams.get(
        INVITATION_CODE
    );
    useEffect(() => {
        if (invitationCode) {
            setItem(INVITATION_CODE, invitationCode);
        }
    }, [invitationCode]);

    return (
        <LandingTemplate mainSlider={true}>
            <NavCards/>
            <BenefitPoints/>
            <OurBenefits getStarted={onGetStarted}/>
            <FreeCode/>
            <HowItWorks getStarted={onGetStarted}/>
            <Commissions/>
            <AboutSection/>
            <Partners/>
        </LandingTemplate>
    );
};

export default Landing;
