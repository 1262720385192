import React from "react";
import { Filters } from "../../../Common/components/Filters";
import { Space, Form, Button } from "antd";
import { periodOptions, lineOptions } from "../../constants";
import { useFilters } from "../../hooks";
import { RadioSelect } from "../../../Common/components/RadioSelect";
import { CheckBoxSelect } from "../../../Common/components/CheckBoxSelect";
import { SettingOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { _t } from "../../../Common/components/InjectIntlContext";
import DateRangePicker from "../../../../common/components/DateRangePicker/DateRangePicker";
import { setFormInputValue } from "../../../../common/helpers";

const radioSelectStyles = { minWidth: 150 };

export function DashboardFilters({ fields = null }) {
  const [filters, updateFilters] = useFilters();
  const [form] = useForm();
  const lineOptionsTranslated = lineOptions(_t);
  return (
    <Filters
      form={form}
      onApply={updateFilters}
      initialValues={filters}
      mainFilters={
        <Space size={22} direction="horizontal" align="center">
          <Form.Item name="date">
            <DateRangePicker
              onChange={(dates) => {
                setFormInputValue(
                  "date",
                  {
                    from: dates[0],
                    to: dates[1],
                  },
                  form
                );
              }}
            />
          </Form.Item>
          {fields}
          <Form.Item name="totals">
            <CheckBoxSelect
              options={lineOptionsTranslated}
              onDropdownVisibleChange={(open) => !open && form.submit()}
            >
              <Button
                size="large"
                shape="circle"
                icon={<SettingOutlined style={{ lineHeight: 0 }} />}
              />
            </CheckBoxSelect>
          </Form.Item>
        </Space>
      }
    />
  );
}

export function ChartFilters() {
  const [filters, updateFilters] = useFilters();
  const [form] = useForm();
  const lineOptionsTranslated = lineOptions(_t);
  const periodOptionsTranslated = periodOptions(_t);

  return (
    <Filters
      form={form}
      onApply={updateFilters}
      initialValues={filters}
      mainFilters={
        <Space size={22} direction="horizontal" align="center">
          <Form.Item name={["columns", "0"]}>
            <RadioSelect
              allowClear={false}
              options={lineOptionsTranslated}
              dropdownStyle={radioSelectStyles}
            />
          </Form.Item>
          <Form.Item name={["columns", "1"]}>
            <RadioSelect
              allowClear={false}
              options={lineOptionsTranslated}
              dropdownStyle={radioSelectStyles}
            />
          </Form.Item>
          <Form.Item name="period">
            <RadioSelect
              allowClear={false}
              options={periodOptionsTranslated}
              dropdownStyle={radioSelectStyles}
            />
          </Form.Item>
        </Space>
      }
    />
  );
}
