import React from "react";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { Card, Checkbox, Form, Input } from "antd";
import {
  convertToPercent,
  setFormInputValue,
} from "../../../../../common/helpers";
import config from "../../../../../system/config";

interface ProgramOptionsProps {
  options?: {
    revshare_reward: string;
    transaction_commission: string;
    royalty: string;
    revshare_fi: string;
    old_gamers_reward: string;
    new_gamers_reward: string;
    new_gamers_lifetime: string;
    old_gamers_lifetime: string;
    old_gamers_reward_start_date: string;
    low_bonus_rate: string;
    cpa_reward: string;
    cpa_required_deposits: string;
    cpa_required_first_deposit: string;
    cpa_required_ng: string;
    cpa_var1: string;
    cpa_var2: string;
    cpa_escaping: string;
    no_rounds_required: string;
  };
  program: string;
  form?: any;
}
const ProgramOptions: React.FC<ProgramOptionsProps> = ({
  options,
  program,
  form,
}) => {
  const {
    revshare_reward,
    transaction_commission,
    royalty,
    revshare_fi,
    old_gamers_reward,
    new_gamers_reward,
    new_gamers_lifetime,
    old_gamers_lifetime,
    old_gamers_reward_start_date,
    low_bonus_rate,
    cpa_reward,
    cpa_required_deposits,
    cpa_required_first_deposit,
    cpa_required_ng,
    cpa_var1,
    cpa_var2,
    cpa_escaping,
    no_rounds_required,
  } = options;

  const cpaOptions = [
    {
      label: `${_t("options_cpa_reward")}, ${config.projectCurrency}`,
      description: _t("options_description_cpa_reward"),
      name: "options[cpa_reward]",
      initialValue: cpa_reward,
    },
    {
      label: `${_t("options_cpa_required_deposits")}, ${config.projectCurrency}`,
      description: _t("options_description_cpa_required_deposits"),
      name: "options[cpa_required_deposits]",
      initialValue: cpa_required_deposits,
    },
    {
      label: `${_t("options_cpa_required_first_deposit")}, ${config.projectCurrency}`,
      description: _t("options_description_cpa_required_first_deposit"),
      name: "options[cpa_required_first_deposit]",
      initialValue: cpa_required_first_deposit,
    },
    {
      label: `${_t("options_cpa_required_ng")}, ${config.projectCurrency}`,
      description: _t("options_description_cpa_required_ng"),
      name: "options[cpa_required_ng]",
      initialValue: cpa_required_ng,
    },
    {
      label: `${_t("options_cpa_var1")}, ${config.projectCurrency}`,
      description: _t("options_description_cpa_var1"),
      name: "options[cpa_var1]",
      initialValue: cpa_var1,
    },
    {
      label: `${_t("options_cpa_var2")}, ${config.projectCurrency}`,
      description: _t("options_description_cpa_var2"),
      name: "options[cpa_var2]",
      initialValue: cpa_var2,
    },
    {
      label: _t("options_cpa_escaping"),
      description: _t("options_description_cpa_escaping"),
      name: "options[cpa_escaping]",
      initialValue: cpa_escaping,
    },
  ];
  const revShareOptions = [
    {
      label: _t("options_revshare_reward"),
      description: _t("options_revshare_reward"),
      name: "options[revshare_reward]",
      initialValue: convertToPercent(+revshare_reward),
    },
    {
      label: _t("options_transaction_commission"),
      description: _t("options_description_transaction_commission"),
      name: "options[transaction_commission]",
      initialValue: convertToPercent(+transaction_commission),
    },
    {
      label: _t("options_royalty"),
      description: _t("options_description_royalty"),
      name: "options[royalty]",
      initialValue: convertToPercent(+royalty),
    },
    {
      label: _t("options_revshare_fi"),
      description: _t("options_description_revshare_fi"),
      name: "options[revshare_fi]",
      initialValue: revshare_fi,
    },
    {
      label: _t("options_old_gamers_reward"),
      description: _t("options_description_old_gamers_reward"),
      name: "options[old_gamers_reward]",
      initialValue: old_gamers_reward,
    },
    {
      label: _t("options_new_gamers_reward"),
      description: _t("options_description_new_gamers_reward"),
      name: "options[new_gamers_reward]",
      initialValue: new_gamers_reward,
    },
    {
      label: _t("options_new_gamers_lifetime"),
      description: _t("options_description_new_gamers_lifetime"),
      name: "options[new_gamers_lifetime]",
      initialValue: new_gamers_lifetime,
    },
    {
      label: _t("options_old_gamers_lifetime"),
      description: _t("options_description_old_gamers_lifetime"),
      name: "options[old_gamers_lifetime]",
      initialValue: old_gamers_lifetime,
    },
    {
      label: _t("options_old_gamers_reward_start_date"),
      description: _t("options_description_old_gamers_reward_start_date"),
      name: "options[old_gamers_reward_start_date]",
      initialValue: old_gamers_reward_start_date,
    },
    {
      label: _t("options_low_bonus_rate"),
      description: _t("options_description_low_bonus_rate"),
      name: "options[low_bonus_rate]",
      initialValue: low_bonus_rate,
    },
  ];

  const handleRequiredRoundsCheck = (e) => {
    setFormInputValue(
      "options[no_rounds_required]",
      e?.target?.checked ? "1" : "",
      form
    );
  };
  return (
    <>
      {program === "cpa" && (
        <Card title="CPA">
          {cpaOptions.map((option) => (
            <Form.Item label={option.label} key={option.name}>
              <Form.Item name={option.name} initialValue={option.initialValue}>
                <Input />
              </Form.Item>
              <span className="input-description">{option.description}</span>
            </Form.Item>
          ))}
          <Form.Item>
            <Form.Item name="options[no_rounds_required]">
              <Checkbox
                defaultChecked={no_rounds_required ? true : false}
                onChange={handleRequiredRoundsCheck}
              >
                {_t("program_option_check_description_label")}
              </Checkbox>
            </Form.Item>
            <span className="input-description">
              {_t("program_option_check_description")}
            </span>
          </Form.Item>
        </Card>
      )}
      {program === "revshare" && (
        <Card title="Revshare">
          {revShareOptions.map((option) => (
            <Form.Item label={option.label} key={option.name}>
              <Form.Item name={option.name} initialValue={option.initialValue}>
                <Input />
              </Form.Item>
              <span className="input-description">{option.description}</span>
            </Form.Item>
          ))}
        </Card>
      )}
    </>
  );
};

export default ProgramOptions;
