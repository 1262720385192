import React from 'react';
import {_t} from "../../Common/components/InjectIntlContext";
import config from "../../../system/config";

const points = (_t) => [
    {
        title: _t("benefit_point_title_1"),
        text: _t("benefit_point_text_1", {divider: "<br/>"}),
    },
    {
        title: _t("benefit_point_title_2"),
        text: _t("benefit_point_text_2", {divider: "<br/>"}),
    },
    {
        title: _t("benefit_point_title_3"),
        text: _t("benefit_point_text_3", {divider: "<br/>"}),
    },

]
const BenefitPoints = () => {
    return (
        <div className="container">
            <div className="benefit-points">
                <div className="benefit-points__img">
                    <img src={`${config.projectImages}/benefit-decor.png`} alt=""/>
                </div>
                <div className="benefit-points__wrap">
                    {points(_t).map((item, idx) => {
                        return (<div className="benefit-point" key={idx}>
                            <h3 className="benefit-point__title">
                                <span className="benefit-point__number">{idx + 1}</span>
                                {item.title}
                            </h3>
                            <p className="benefit-point__text" dangerouslySetInnerHTML={{__html: item.text}}></p>
                        </div>)
                    })}
                </div>
            </div>
        </div>
    );
};

export default BenefitPoints;