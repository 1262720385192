import React from "react";

export const messages = {
  "All components": {
    id: "All components",
    defaultMessage: "All components",
  },
  Dashboard: {
    id: "Dashboard",
    defaultMessage: "Dashboard",
  },
  "Promo Materials": {
    id: "Promo Materials",
    defaultMessage: "Promo Materials",
  },
  Trackers: {
    id: "Trackers",
    defaultMessage: "Trackers",
  },
  Payments: {
    id: "Payments",
    defaultMessage: "Payments",
  },
  Affiliates: {
    id: "Affiliates",
    defaultMessage: "Affiliates",
  },
  Costs: {
    id: "Costs",
    defaultMessage: "Postbacks",
  },
  Brands: {
    id: "Brands",
    defaultMessage: "Brands",
  },
  Commission: {
    id: "Commission",
    defaultMessage: "Commission",
  },
  Faq: {
    id: "Faq",
    defaultMessage: "Faq",
  },
  Contacts: {
    id: "Contacts",
    defaultMessage: "Contacts",
  },
  Username: {
    id: "Username",
    defaultMessage: "Username",
  },
  Commisions: {
    id: "Commisions",
    defaultMessage: "Commisions",
  },
  General: {
    id: "General",
    defaultMessage: "General",
  },
  "Page is not found": {
    id: "Page is not found",
    defaultMessage: "Page is not found",
  },
  Logout: {
    id: "Logout",
    defaultMessage: "Logout",
  },
  "Sub Id": {
    id: "Sub Id",
    defaultMessage: "Sub Id",
  },
  Landings: {
    id: "Landings",
    defaultMessage: "Landings",
  },
  Gamers: {
    id: "Gamers",
    defaultMessage: "Gamers",
  },
  "Personal information": {
    id: "Personal information",
    defaultMessage: "Personal information",
  },
  "User Information": {
    id: "User Information",
    defaultMessage: "User Information",
  },
  "Change Password": {
    id: "Change Password",
    defaultMessage: "Change Password",
  },
  "Other information": {
    id: "Other information",
    defaultMessage: "Other information",
  },
  Login: {
    id: "Login",
    defaultMessage: "Login",
  },
  Email: {
    id: "Email",
    defaultMessage: "Email",
  },
  Nickname: {
    id: "Nickname",
    defaultMessage: "Nickname",
  },
  "Authentication log": {
    id: "Authentication log",
    defaultMessage: "Authentication log",
  },
  "CSV Settings": {
    id: "CSV Settings",
    defaultMessage: "CSV Settings",
  },
  "Email Subscriptions": {
    id: "Email Subscriptions",
    defaultMessage: "Email Subscriptions",
  },
  current_period: {
    id: "current_period",
    defaultMessage: "Balance of the current period",
  },
  funds_to_pay: {
    id: "funds_to_pay",
    defaultMessage: "Funds to pay",
  },
  payout: {
    id: "payout",
    defaultMessage: "Payout",
  },
  profit: {
    id: "profit",
    defaultMessage: "Total profit",
  },
  your_key: {
    id: "your_key",
    defaultMessage: "Your key",
  },
  enable_CSV_key: {
    id: "enable_CSV_key",
    defaultMessage: "Enable CSV key",
  },
  allowed_IPs: {
    id: "allowed_IPs",
    defaultMessage: "Allowed IPs",
  },
  "Please input IP or delete this field": {
    id: "Please input IP or delete this field",
    defaultMessage: "Please input IP or delete this field",
  },
  Apply: {
    id: "Apply",
    defaultMessage: "Apply",
  },
  "Request CSV Key": {
    id: "Request CSV Key",
    defaultMessage: "Request CSV Key",
  },
  language: {
    id: "language",
    defaultMessage: "language",
  },
  current_password: {
    id: "Current Password",
    defaultMessage: "Current Password",
  },
  confirm_password: {
    id: "Confirm password",
    defaultMessage: "Confirm password",
  },
  new_password: {
    id: "New password",
    defaultMessage: "New password",
  },
  phone: {
    id: "Phone",
    defaultMessage: "Phone",
  },
  first_name: {
    id: "First name",
    defaultMessage: "First name",
  },
  last_name: {
    id: "Last name",
    defaultMessage: "Last name",
  },
  show: {
    id: "show",
    defaultMessage: "show",
  },
  url: {
    id: "url",
    defaultMessage: "url",
  },
  game: {
    id: "game",
    defaultMessage: "game",
  },
  refcode: {
    id: "refcode",
    defaultMessage: "refcode",
  },
  postbacks_debug_info: {
    id: "Postbacks Debug Info",
    defaultMessage: "Postbacks Debug Info",
  },
  postback_deposit: {
    id: "Postback Deposit",
    defaultMessage: "Postback Deposit",
  },
  postback_first_deposit: {
    id: "Postback First Deposit",
    defaultMessage: "Postback First Deposit",
  },
  postback_register: {
    id: "Postback Register",
    defaultMessage: "Postback Register",
  },
  postback_qualification: {
    id: "Postback Qualification",
    defaultMessage: "Postback Qualification",
  },
  postback_install: {
    id: "Postback Install",
    defaultMessage: "Postback Install",
  },
  created: {
    id: "Created",
    defaultMessage: "Created",
  },
  status: {
    id: "Status",
    defaultMessage: "Status",
  },
  attempts: {
    id: "Attempts",
    defaultMessage: "Attempts",
  },
  ref_code: {
    id: "Ref code",
    defaultMessage: "Ref code",
  },
  reg_date: {
    id: "Reg. Date",
    defaultMessage: "Reg. Date",
  },
  gamer: {
    id: "Gamer",
    defaultMessage: "Gamer",
  },
  info: {
    id: "Info",
    defaultMessage: "Info",
  },
  project: {
    id: "Project",
    defaultMessage: "Project",
  },
  amount: {
    id: "Amount",
    defaultMessage: "Amount",
  },
  postback_debug: {
    id: "Postback Debug",
    defaultMessage: "Postback Debug",
  },
  Name: {
    id: "Name",
    defaultMessage: "Name",
  },
  Id: {
    id: "Id",
    defaultMessage: "Id",
  },
  "Gamer Profile": {
    id: "Gamer Profile",
    defaultMessage: "Gamer Profile",
  },
  id: {
    id: "ID",
    defaultMessage: "ID",
  },
  "Confirm Date": {
    id: "Confirm Date",
    defaultMessage: "Confirm Date",
  },
  Promo: {
    id: "Promo",
    defaultMessage: "Promo",
  },
  SubID: {
    id: "SubID",
    defaultMessage: "SubID",
  },
  SubID2: {
    id: "SubID 2",
    defaultMessage: "SubID 2",
  },
  Program: {
    id: "Program",
    defaultMessage: "Program",
  },
  Type: {
    id: "Type",
    defaultMessage: "Type",
  },
  Created: {
    id: "Created",
    defaultMessage: "Created",
  },
  find_promo: {
    id: "find_promo",
    defaultMessage: "Find promo",
  },
  promo_type: {
    id: "promo_type",
    defaultMessage: "Promo type",
  },
  Save: {
    id: "Save",
    defaultMessage: "Save",
  },
  view_source: {
    id: "view_source",
    defaultMessage: "View source",
  },
  contacts: {
    id: "contacts",
    defaultMessage: "Contacts",
  },
  details: {
    id: "details",
    defaultMessage: "Details",
  },
  "Please input details": {
    id: "Please input details",
    defaultMessage: "Please input details",
  },
  promo_order_attention_message: {
    id: "promo_order_attention_message",
    defaultMessage:
      "This is a place where you can easily order an exclusive text banner or any casino-related graphical promo material, including online casino description, banner or branding – absolutely free of charge! Please be as precise as possible when describing your demand to the promo-materials.\n" +
      "\n" +
      "If you’re ordering a text, please make sure you’ve mentioned its volume and number of symbols.\n" +
      "\n" +
      "If you’re ordering graphic materials, please mention the size, format and contents or the main idea.\n" +
      "\n" +
      "On placing the order, our account manager will contact you to learn the details concerning the deadlines, or any other information that may help to fulfill your order.",
  },
  "Archive of promo’s": {
    id: "Archive of promo’s",
    defaultMessage: "Archive of promo’s",
  },
  use_redirector: {
    id: "use_redirector",
    defaultMessage: "Use redirector",
  },
  tracker_id: {
    id: "tracker_id",
    defaultMessage: "Tracker Id",
  },
  sub_id: {
    id: "sub_id",
    defaultMessage: "Sub Id",
  },
  sub_id2: {
    id: "sub_id2",
    defaultMessage: "Sub Id2",
  },
  register_url: {
    id: "register_url",
    defaultMessage: "Register url",
  },
  deposit_url: {
    id: "deposit_url",
    defaultMessage: "Deposit url",
  },
  first_deposit_url: {
    id: "first_deposit_url",
    defaultMessage: "First deposit url",
  },
  qualification_url: {
    id: "qualification_url",
    defaultMessage: "Qualification url",
  },
  install_url: {
    id: "install_url",
    defaultMessage: "Install url",
  },
  Tracker: {
    id: "Tracker",
    defaultMessage: "Tracker",
  },
  IP: {
    id: "IP",
    defaultMessage: "IP",
  },
  Country: {
    id: "Country",
    defaultMessage: "Country",
  },
  City: {
    id: "City",
    defaultMessage: "City",
  },
  "Registration details": {
    id: "Registration details",
    defaultMessage: "Registration details",
  },
  Activity: {
    id: "Activity",
    defaultMessage: "Activity",
  },
  "First deposit date": {
    id: "First deposit date",
    defaultMessage: "First deposit date",
  },
  "First deposit": {
    id: "First deposit",
    defaultMessage: "First deposit",
  },
  "Casino promo": {
    id: "Casino promo",
    defaultMessage: "Casino promo",
  },
  Qualification: {
    id: "Qualification",
    defaultMessage: "Qualification",
  },
  "Multi accounts": {
    id: "Multi accounts",
    defaultMessage: "Multi accounts",
  },
  "Deposit Transactions Count": {
    id: "Deposit Transactions Count",
    defaultMessage: "Deposit Transactions Count",
  },
  No: {
    id: "No",
    defaultMessage: "No",
  },
  Bets: {
    id: "Bets",
    defaultMessage: "Bets",
  },
  Date: {
    id: "Date",
    defaultMessage: "Date",
  },
  "Partner income": {
    id: "Partner income",
    defaultMessage: "Partner income",
  },
  Hits: {
    id: "Hits",
    defaultMessage: "Hits",
  },
  Hosts: {
    id: "Hosts",
    defaultMessage: "Hosts",
  },
  Installs: {
    id: "Installs",
    defaultMessage: "Installs",
  },
  "Reg.": {
    id: "Reg.",
    defaultMessage: "Reg.",
  },
  "Reg.All": {
    id: "Reg.All",
    defaultMessage: "Reg.All",
  },
  Rounds: {
    id: "Rounds",
    defaultMessage: "Rounds",
  },
  Project: {
    id: "Project",
    defaultMessage: "Project",
  },
  "Landing page": {
    id: "Landing page",
    defaultMessage: "Landing page",
  },
  "LP hosts": {
    id: "LP hosts",
    defaultMessage: "LP hosts",
  },
  "LP hits": {
    id: "LP hits",
    defaultMessage: "LP hits",
  },
  "Sub Id 2": {
    id: "Sub Id 2",
    defaultMessage: "Sub Id 2",
  },
  "Partner inc": {
    id: "Partner inc",
    defaultMessage: "Partner inc",
  },
  "Full income": {
    id: "Full income",
    defaultMessage: "Full income",
  },
  Bonus: {
    id: "Bonus",
    defaultMessage: "Bonus",
  },
  "Net Gaming": {
    id: "Net Gaming",
    defaultMessage: "Net Gaming",
  },
  "Qualified players": {
    id: "Qualified players",
    defaultMessage: "Qualified players",
  },
  Withdraws: {
    id: "Withdraws",
    defaultMessage: "Withdraws",
  },
  Deposits: {
    id: "Deposits",
    defaultMessage: "Deposits",
  },
  "Deposits count": {
    id: "Deposits count",
    defaultMessage: "Deposits count",
  },
  "1st depositors": {
    id: "1st depositors",
    defaultMessage: "1st depositors",
  },
  "1st depositsamount": {
    id: "1st depositsamount",
    defaultMessage: "1st depositsamount",
  },
  "Authentication in progress, please wait...": {
    id: "Authentication in progress, please wait...",
    defaultMessage: "Authentication in progress, please wait...",
  },
  "Reset password is in progress, please wait...": {
    id: "Reset password is in progress, please wait...",
    defaultMessage: "Reset password is in progress, please wait...",
  },
  "Your new password is:": {
    id: "Your new password is:",
    defaultMessage: "Your new password is:",
  },
  "Something went wrong": {
    id: "Something went wrong",
    defaultMessage: "Something went wrong",
  },
  "Url is not valid, please contact your admin": {
    id: "Url is not valid, please contact your admin",
    defaultMessage: "Url is not valid, please contact your admin",
  },
  "Order promo": {
    id: "Order promo",
    defaultMessage: "Order promo",
  },
  "Step 1": {
    id: "Step 1",
    defaultMessage: "Step 1",
  },
  select_program: {
    id: "select_program",
    defaultMessage: "Select program",
  },
  "Step 2": {
    id: "Step 2",
    defaultMessage: "Step 2",
  },
  "Step 3": {
    id: "Step 3",
    defaultMessage: "Step 3",
  },
  link_to: {
    id: "link_to",
    defaultMessage: "Link to",
  },
  back: {
    id: "Back",
    defaultMessage: "Back",
  },
  next: {
    id: "next",
    defaultMessage: "Next",
  },
  get_code: {
    id: "get_code",
    defaultMessage: "Get Code",
  },
  create_new_promo: {
    id: "create_new_promo",
    defaultMessage: "Create new promo",
  },
  attention: {
    id: "Attention",
    defaultMessage: "Attention",
  },
  square: {
    id: "square",
    defaultMessage: "Square",
  },
  tag: {
    id: "tag",
    defaultMessage: "Tag",
  },
  format: {
    id: "format",
    defaultMessage: "Format",
  },
  vertical: {
    id: "vertical",
    defaultMessage: "Vertical",
  },
  horizontal: {
    id: "horizontal",
    defaultMessage: "Horizontal",
  },
  "Gamer ID": {
    id: "Gamer ID",
    defaultMessage: "Gamer ID",
  },
  "Gamer IP": {
    id: "Gamer IP",
    defaultMessage: "Gamer IP",
  },
  "Click Id": {
    id: "Click Id",
    defaultMessage: "Click Id",
  },
  "Please enter your email address": {
    id: "Please enter your email address",
    defaultMessage: "Please enter your email address",
  },
  "E-mail": {
    id: "E-mail",
    defaultMessage: "E-mail",
  },
  Remind: {
    id: "Remind",
    defaultMessage: "Remind",
  },
  "Reset password": {
    id: "Reset password",
    defaultMessage: "Reset password",
  },
  "Please visit your email and follow the instruction": {
    id: "Please visit your email and follow the instruction",
    defaultMessage: "Please visit your email and follow the instruction",
  },
  ltv: {
    id: "LTV",
    defaultMessage: "LTV",
  },
  regs: {
    id: "Registration",
    defaultMessage: "Registration",
  },
  first_deposits: {
    id: "1st depositors",
    defaultMessage: "1st depositors",
  },
  deposits: {
    id: "Deposits",
    defaultMessage: "Deposits",
  },
  net_gaming: {
    id: "Net gaming",
    defaultMessage: "Net gaming",
  },
  partner_income: {
    id: "Partner income",
    defaultMessage: "Partner income",
  },
  "Gamer Id": {
    id: "Gamer id",
    defaultMessage: "Gamer id",
  },
  "Reg ip": {
    id: "Reg ip",
    defaultMessage: "Reg ip",
  },
  "Full profit": {
    id: "Full profit",
    defaultMessage: "Full profit",
  },
  "Reg Date": {
    id: "Reg Date",
    defaultMessage: "Reg Date",
  },
  "Dep. transactions count": {
    id: "Dep. transactions count",
    defaultMessage: "Dep. transactions count",
  },
  "Gemers by geo": {
    id: "Gemers by geo",
    defaultMessage: "Gemers by geo",
  },
  "Only with statistics": {
    id: "Only with statistics",
    defaultMessage: "Only with statistics",
  },
  All: {
    id: "All",
    defaultMessage: "All",
  },
  welcome_title: {
    id: "welcome_title",
    defaultMessage: "Make money with best brands",
  },
  welcome_message: {
    id: "welcome_message",
    defaultMessage:
      "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born",
  },
  get_started: {
    id: "get_started",
    defaultMessage: "Get Started",
  },
  read_more: {
    id: "read_more",
    defaultMessage: "Read more",
  },
  our_benefits: {
    id: "our_benefits",
    defaultMessage: "Our benefits",
  },
  benefit_1: {
    id: "benefit_1",
    defaultMessage: "Make money with \n best brands",
  },
  benefit_2: {
    id: "benefit_2",
    defaultMessage: "Earn up to 45%  \n commission life time",
  },
  benefit_3: {
    id: "benefit_3",
    defaultMessage: "Enjoy best partners \n support in the industry",
  },
  how_it_works: {
    id: "how_it_works",
    defaultMessage: "How it works",
  },
  how_1: {
    id: "how_1",
    defaultMessage: "Register Your Account",
  },
  how_2: {
    id: "how_2",
    defaultMessage: "Choose Promo Materials",
  },
  how_3: {
    id: "how_3",
    defaultMessage: "Publish On Your Site",
  },
  how_4: {
    id: "how_4",
    defaultMessage: "Earn Money For Your Activity",
  },
  Commissions: {
    id: "Commissions",
    defaultMessage: "Commissions",
  },
  revshare_commission: {
    id: "revshare_commission",
    defaultMessage: "RevShare commission",
  },
  revshare_commission_desc: {
    id: "revshare_commission_desc",
    defaultMessage:
      "Earn up to 40% from brand's income with RevShare commission",
  },
  cpa_program: {
    id: "cpa_program",
    defaultMessage: "CPA program",
  },
  cpa_program_desc: {
    id: "cpa_program_desc",
    defaultMessage: "Highest CPA payouts on the market",
  },
  subaffiliate_program: {
    id: "subaffiliate_program",
    defaultMessage: "Sub-Affiliate program",
  },
  subaffiliate_program_desc: {
    id: "subaffiliate_program_desc",
    defaultMessage:
      "The base rate for the sub-affiliates program is 5% of the income of the invited Partners",
  },
  "visit site": {
    id: "visit site",
    defaultMessage: "visit site",
  },
  "Project information": {
    id: "Project information",
    defaultMessage: "Project information",
  },
  affiliate_programs: {
    id: "affiliate_programs",
    defaultMessage: "Affiliate programs",
  },
  RevShare: {
    id: "RevShare",
    defaultMessage: "RevShare",
  },
  CPA: {
    id: "CPA",
    defaultMessage: "CPA",
  },
  software_supplier: {
    id: "software_supplier",
    defaultMessage: "The software supplier",
  },
  payment_provider: {
    id: "payment_provider",
    defaultMessage: "Payment service providers",
  },
  target_audience: {
    id: "target_audience",
    defaultMessage: "Target audience",
  },
  Currency: {
    id: "Currency",
    defaultMessage: "Currency",
  },
  Languages: {
    id: "Languages",
    defaultMessage: "Languages",
  },
  download_versions: {
    id: "download_versions",
    defaultMessage: "Download versions",
  },
  mobile_version: {
    id: "mobile_version",
    defaultMessage: "Mobile version",
  },
  close: {
    id: "close",
    defaultMessage: "close",
  },
  sign_in: {
    id: "sign_in",
    defaultMessage: "Sign In",
  },
  sign_up: {
    id: "sign_up",
    defaultMessage: "Sign Up",
  },
  login: {
    id: "login",
    defaultMessage: "Login",
  },
  FAQ: {
    id: "FAQ",
    defaultMessage: "FAQ",
  },
  menu: {
    id: "menu",
    defaultMessage: "menu",
  },
  Portfolio: {
    id: "Portfolio",
    defaultMessage: "Portfolio",
  },
  general: {
    id: "general",
    defaultMessage: "General questions",
  },
  financial: {
    id: "financial",
    defaultMessage: "Financial questions",
  },
  technical: {
    id: "technical",
    defaultMessage: "Technical questions",
  },
  popular: {
    id: "popular",
    defaultMessage: "most popular questions",
  },
  revshare_title: {
    id: "revshare_title",
    defaultMessage: "RevShare commission",
  },
  revshare_1: {
    id: "revshare_1",
    defaultMessage:
      "The traditional commission, which provides the strong revenue stream from the gambling project’s income",
  },
  revshare_2: {
    id: "revshare_2",
    defaultMessage:
      "The RevShare program is available to all the partners and doesn’t require any activation",
  },
  revshare_3: {
    id: "revshare_3",
    defaultMessage:
      "This is the best long term solution as it guarantees regular income during the whole lifetime value of the invited players",
  },
  revshare_4: {
    id: "revshare_4",
    defaultMessage:
      "The interest rate in RevShare is dynamic and depends only on the amount of new players invited by a Partner",
  },
  cpa_title: {
    id: "cpa_title",
    defaultMessage: "CPA program",
  },
  cpa_1: {
    id: "cpa_1",
    defaultMessage:
      "The CPA commission allows earning the fixed price for all the invited players",
  },
  cpa_2: {
    id: "cpa_2",
    defaultMessage:
      "The terms of payment and the fixed price for a single player are determined individually for each Partner",
  },
  cpa_3: {
    id: "cpa_3",
    defaultMessage: "Fraud, spam and incent traffic are forbidden",
  },
  // cpa_4: {
  //   id: "cpa_4",
  //   defaultMessage:
  //     "The price for each player may vary from EUR 25 depending on specific program, partner’s traffic quality and terms of payment for a player",
  // },
  cpa_5: {
    id: "cpa_5",
    defaultMessage:
      "In case of fraud, Affiliate Program stops CPA immediately and blocks Partner's balance due to this cause for an unlimited period after detection of the fraudulent activity",
  },
  cpa_6: {
    id: "cpa_6",
    defaultMessage:
      "In case of traffic low quality, Affiliate Program informs Partners about the termination of the CPA agreement the day before termination",
  },
  cpa_7: {
    id: "cpa_7",
    defaultMessage:
      "If Partners does not answer, the income of the partner (i.e. payments for the New Depositing Players that came after termination) will be recalculated according to the terms of RevShare commission model",
  },
  subaffiliate_title: {
    id: "subaffiliate_title",
    defaultMessage: "Sub-Affiliate program",
  },
  subaffiliate_1: {
    id: "subaffiliate_1",
    defaultMessage:
      "Sub-Affiliate program provides the opportunity to earn on engaging new Partners",
  },
  subaffiliate_2: {
    id: "subaffiliate_2",
    defaultMessage:
      "The base rate for the sub-affiliates program is 3% of the income of the invited Partners",
  },
  subaffiliate_3: {
    id: "subaffiliate_3",
    defaultMessage:
      "To evaluate the effectiveness of your sub-affiliates there's a detailed statistics available in your dashboard that can be filtered by dates/ by invites/ by sub-affiliates",
  },
  subaffiliate_4: {
    id: "subaffiliate_4",
    defaultMessage:
      "In your dashboard you can also find special promotional materials designed to attract new Partners to the Sub-Affiliates program",
  },
  submit: {
    id: "submit",
    defaultMessage: "Submit",
  },
  enter_login_message: {
    id: "enter_login_message",
    defaultMessage: "Please input your login!",
  },
  not_valid_email_message: {
    id: "not_valid_email_message",
    defaultMessage: "The input is not valid E-mail!",
  },
  input_email_message: {
    id: "input_email_message",
    defaultMessage: "Please input your E-mail!",
  },
  input_password_message: {
    id: "input_password_message",
    defaultMessage: "Please input your password!",
  },
  min_password_message: {
    id: "min_password_message",
    defaultMessage: "Your password should be at least 6 characters",
  },
  Password: {
    id: "Password",
    defaultMessage: "Password",
  },
  confirm_password_message: {
    id: "confirm_password_message",
    defaultMessage: "Please confirm your password!",
  },
  error_confirm_password_message: {
    id: "error_confirm_password_message",
    defaultMessage: "The two passwords that you entered do not match!",
  },
  accept_terms: {
    id: "accept_terms",
    defaultMessage: "Should accept terms and conditions",
  },
  agree_with: {
    id: "agree_with",
    defaultMessage: "I agree with",
  },
  terms_conditions: {
    id: "terms_conditions",
    defaultMessage: "terms and conditions",
  },
  register: {
    id: "register",
    defaultMessage: "Register",
  },
  get_in_touch: {
    id: "get_in_touch",
    defaultMessage: "get in touch",
  },
  contacts_welcome_message: {
    id: "contacts_welcome_message",
    defaultMessage:
      "We would love to hear from you. If you'd like to talk about a new business enquiry please contact us.",
  },
  your_message: {
    id: "your_message",
    defaultMessage: "Your message",
  },
  Send: {
    id: "Send",
    defaultMessage: "Send",
  },
  message_sent: {
    id: "message_sent",
    defaultMessage: "Your message has been sent",
  },
  validation_errors: {
    id: "validation_errors",
    defaultMessage: "got some validation errors",
  },
  input_name: {
    id: "input_name",
    defaultMessage: "Please input name",
  },
  input_email: {
    id: "input_email",
    defaultMessage: "Please input email",
  },
  postback_options: {
    id: "postback_options",
    defaultMessage: "Postback options",
  },
  Manage: {
    id: "Manage",
    defaultMessage: "Manage",
  },
  Users: {
    id: "Users",
    defaultMessage: "Users",
  },
  trackers_list: {
    id: "trackers_list",
    defaultMessage: "Trackers List",
  },
  create_tracker: {
    id: "create_tracker",
    defaultMessage: "create tracker",
  },
  created_at: {
    id: "created_at",
    defaultMessage: "Created at",
  },
  Action: {
    id: "Action",
    defaultMessage: "Action",
  },
  tracker_name: {
    id: "tracker_name",
    defaultMessage: "Tracker name",
  },
  name_surname: {
    id: "name_surname",
    defaultMessage: "Name Surname:",
  },
  legal_address: {
    id: "legal_address",
    defaultMessage: "Legal Address:",
  },
  bank_name: {
    id: "bank_name",
    defaultMessage: "Bank name:",
  },
  bank_address: {
    id: "bank_address",
    defaultMessage: "Bank address:",
  },
  bank_account: {
    id: "bank_account",
    defaultMessage: "Account Nr or IBAN:",
  },
  swift: {
    id: "swift",
    defaultMessage: "SWIFT:",
  },
  payment_details: {
    id: "payment_details",
    defaultMessage: "Payment Details",
  },
  payment_contact_admins: {
    id: "payment_contact_admins",
    defaultMessage: "For changing payment details contact the support",
  },
  payment_system: {
    id: "payment_system",
    defaultMessage: "Payment system",
  },
  Accruals: {
    id: "Accruals",
    defaultMessage: "Accruals",
  },
  accounting_period: {
    id: "accounting_period",
    defaultMessage: "Accounting period",
  },
  first_depositors: {
    id: "first_depositors",
    defaultMessage: "First Depositors*",
  },
  bonuses_fines: {
    id: "bonuses_fines",
    defaultMessage: "Bonuses And Fines",
  },
  subaffiliates_income: {
    id: "subaffiliates_income",
    defaultMessage: "Sub-Affiliates Income",
  },
  your_profit: {
    id: "your_profit",
    defaultMessage: "Your profit",
  },
  income_from_subaffiliates: {
    id: "income_from_subaffiliates",
    defaultMessage: "Your income from Sub-Affiliates:",
  },
  code: {
    id: "code",
    defaultMessage: "Code:",
  },
  subaffiliates_profit: {
    id: "subaffiliates_profit",
    defaultMessage: "Sub-Affiliates profit",
  },
  all_subaffiliates: {
    id: "all_subaffiliates",
    defaultMessage: "All Sub-Affiliates",
  },
  search: {
    id: "search",
    defaultMessage: "Search",
  },
  finances: {
    id: "finances",
    defaultMessage: "Finances",
  },
  role_status: {
    id: "role_status",
    defaultMessage: "Role / Status",
  },
  dates: {
    id: "dates",
    defaultMessage: "Dates",
  },
  login_as: {
    id: "login_as",
    defaultMessage: "Login as",
  },
  registered: {
    id: "registered",
    defaultMessage: "Registered",
  },
  last_login: {
    id: "last_login",
    defaultMessage: "Last login",
  },
  never_logged_in: {
    id: "never_logged_in",
    defaultMessage: "Never logged in",
  },
  activated: {
    id: "activated",
    defaultMessage: "Activated",
  },
  not_activated: {
    id: "not_activated",
    defaultMessage: "Not activated",
  },
  profile: {
    id: "profile",
    defaultMessage: "Profile",
  },
  traffic_type: {
    id: "traffic_type",
    defaultMessage: "Traffic type",
  },
  nickname: {
    id: "nickname",
    defaultMessage: "Custom nickname",
  },
  cash_type: {
    id: "cash_type",
    defaultMessage: "Cash Type",
  },
  wallet: {
    id: "wallet",
    defaultMessage: "Wallet",
  },
  ex_ussr: {
    id: "ex_ussr",
    defaultMessage: "Ex-USSR",
  },
  yes: {
    id: "yes",
    defaultMessage: "Yes",
  },
  disabled: {
    id: "disabled",
    defaultMessage: "Disabled",
  },
  enabled: {
    id: "enabled",
    defaultMessage: "Enabled",
  },
  active: {
    id: "active",
    defaultMessage: "Active",
  },
  banned: {
    id: "banned",
    defaultMessage: "Banned",
  },
  suspend: {
    id: "suspend",
    defaultMessage: "Suspend",
  },
  hold: {
    id: "hold",
    defaultMessage: "Hold",
  },
  registration_date: {
    id: "registration_date",
    defaultMessage: "Registration date",
  },
  profile_update_date: {
    id: "profile_update_date",
    defaultMessage: "Last profile update date",
  },
  projects: {
    id: "projects",
    defaultMessage: "Projects",
  },
  settings: {
    id: "settings",
    defaultMessage: "Settings",
  },
  allow_logins: {
    id: "allow_logins",
    defaultMessage: "Allow gamers logins in ext. stats",
  },
  enable_postbacks: {
    id: "enable_postbacks",
    defaultMessage: "Enable postbacks",
  },
  hide_finance: {
    id: "hide_finance",
    defaultMessage: "Hide Finance",
  },
  skype: {
    id: "skype",
    defaultMessage: "Skype",
  },
  subscription_language: {
    id: "subscription_language",
    defaultMessage: "Subscription Language",
  },
  is_subscribed: {
    id: "is_subscribed",
    defaultMessage: "Is Subscribed",
  },
  Http_Referer: {
    id: "Http_Referer",
    defaultMessage: "Http_Referer",
  },
  show_in_tops: {
    id: "show_in_tops",
    defaultMessage: "Show name in tops",
  },
  allow_csv: {
    id: "allow_csv",
    defaultMessage: "Allow CSV Settings export",
  },
  password_reset_link: {
    id: "password_reset_link",
    defaultMessage: "Password reset link",
  },
  not_specified: {
    id: "not_specified",
    defaultMessage: "Not specified",
  },
  negative_carryover: {
    id: "negative_carryover",
    defaultMessage: "Negative Carryover",
  },
  manager: {
    id: "manager",
    defaultMessage: "Manager",
  },
  notes: {
    id: "notes",
    defaultMessage: "Notes",
  },
  resources: {
    id: "resources",
    defaultMessage: "Resources",
  },
  general_postback: {
    id: "general_postback",
    defaultMessage: "General Postback URLs",
  },
  registration_url: {
    id: "registration_url",
    defaultMessage: "Registration Url",
  },
  specials: {
    id: "specials",
    defaultMessage: "Specials",
  },
  add_operation: {
    id: "add_operation",
    defaultMessage: "Add operation",
  },
  payouts: {
    id: "payouts",
    defaultMessage: "Payouts",
  },
  ip_multiples: {
    id: "ip_multiples",
    defaultMessage: "IP Multiples",
  },
  gamer_ip_multiples: {
    id: "gamer_ip_multiples",
    defaultMessage: "Gamer IP Multiples",
  },
  payments_data_log: {
    id: "payments_data_log",
    defaultMessage: "Payment Data Changing Log",
  },
  status_changing_log: {
    id: "status_changing_log",
    defaultMessage: "Status Changing Log",
  },
  role_changing_log: {
    id: "role_changing_log",
    defaultMessage: "Role Changing Log",
  },
  negative_compensation: {
    id: "negative_compensation",
    defaultMessage: "Negative Compensations",
  },
  operation: {
    id: "operation",
    defaultMessage: "operation",
  },
  comment: {
    id: "comment",
    defaultMessage: "comment",
  },
  visible: {
    id: "visible",
    defaultMessage: "visible",
  },
  fine: {
    id: "fine",
    defaultMessage: "Fine",
  },
  prepayment: {
    id: "prepayment",
    defaultMessage: "Prepayment",
  },
  bonus: {
    id: "bonus",
    defaultMessage: "bonus",
  },
  input_amount: {
    id: "input_amount",
    defaultMessage: "Please input amount",
  },
  select_project: {
    id: "select_project",
    defaultMessage: "Please select project",
  },
  allowed: {
    id: "allowed",
    defaultMessage: "allowed",
  },
  denied: {
    id: "denied",
    defaultMessage: "denied",
  },
  landing_pages: {
    id: "landing_pages",
    defaultMessage: "Landing Pages",
  },
  programs: {
    id: "programs",
    defaultMessage: "Programs",
  },
  last_stats_view: {
    id: "last_stats_view",
    defaultMessage: "Partner Last Stats View",
  },
  financial_performance: {
    id: "financial_performance",
    defaultMessage: "Financial Performance",
  },
  balance_funds_to_pay: {
    id: "balance_funds_to_pay",
    defaultMessage: "Balance (Funds to pay)",
  },
  users_profile: {
    id: "users_profile",
    defaultMessage: "Users Profile",
  },
  login_attempt: {
    id: "login_attempt",
    defaultMessage: "Login/Attempt",
  },
  partner: {
    id: "partner",
    defaultMessage: "Partner",
  },
  changed_by: {
    id: "changed_by",
    defaultMessage: "Changed By",
  },
  Data: {
    id: "Data",
    defaultMessage: "Data",
  },
  undefined: {
    id: "undefined",
    defaultMessage: "Undefined",
  },
  successful_registration_message: {
    id: "successful_registration_message",
    defaultMessage:
      "Registration completed <br> Now you need to activate your account. E-mail with activation link has been sent to your email.",
  },
  welcome: {
    id: "welcome",
    defaultMessage: "Welcome",
  },
  successfully_registered: {
    id: "successfully_registered",
    defaultMessage: "Successfully Registered",
  },
  successfully_updated: {
    id: "successfully_updated",
    defaultMessage: "Successfully Updated",
  },
  profile_update_error_message: {
    id: "profile_update_error_message",
    defaultMessage: "Error occurred during profile update process",
  },
  success_login: {
    id: "success_login",
    defaultMessage: "You are successfully logged in",
  },
  check_credentials: {
    id: "check_credentials",
    defaultMessage: "Please check your credentials",
  },
  select_special_project: {
    id: "select_special_project",
    defaultMessage: "Please select Special Project",
  },
  select_special_program: {
    id: "select_special_program",
    defaultMessage: "Please select Special Program",
  },
  select_special_landing: {
    id: "select_special_landing",
    defaultMessage: "Please select Special Landing Page",
  },
  recaptcha_required: {
    id: "recaptcha_required",
    defaultMessage: "Recaptcha is required",
  },
  conditions: {
    id: "conditions",
    defaultMessage: "Terms and conditions",
  },
  testimonials: {
    id: "testimonials",
    defaultMessage: "Testimonials",
  },
  empty_filters: {
    id: "empty_filters",
    defaultMessage: "Filters are empty",
  },
  reg: {
    id: "reg",
    defaultMessage: "Reg",
  },
  translations: {
    id: "translations",
    defaultMessage: "Translations",
  },
  content: {
    id: "content",
    defaultMessage: "Content",
  },
  keys: {
    id: "keys",
    defaultMessage: "Keys",
  },
  no_data: {
    id: "no_data",
    defaultMessage: "No Data",
  },
  show_per_page: {
    id: "show_per_page",
    defaultMessage: "Show rows per page",
  },
  days: {
    id: "days",
    defaultMessage: "Days",
  },
  total: {
    id: "total",
    defaultMessage: "Total",
  },
  show_hide_all: {
    id: "show_hide_all",
    defaultMessage: "Show/Hide all",
  },
  chart: {
    id: "chart",
    defaultMessage: "Chart",
  },
  filter: {
    id: "filter",
    defaultMessage: "Filter",
  },
  create: {
    id: "create",
    defaultMessage: "Create",
  },
  by_days: {
    id: "by_days",
    defaultMessage: "By Days",
  },
  by_invites: {
    id: "by_invites",
    defaultMessage: "By Invites",
  },
  by_sub_affiliates: {
    id: "by_sub_affiliates",
    defaultMessage: "By sub-affiliates",
  },
  merge: {
    id: "merge",
    defaultMessage: "Merge",
  },
  news: {
    id: "news",
    defaultMessage: "News",
  },
  position: {
    id: "position",
    defaultMessage: "Position",
  },
  prizes: {
    id: "prizes",
    defaultMessage: "Prizes",
  },
  results: {
    id: "results",
    defaultMessage: "Results",
  },
  information: {
    id: "information",
    defaultMessage: "Information",
  },
  affiliate_id: {
    id: "affiliate_id",
    defaultMessage: "Partner ID",
  },
  value: {
    id: "value",
    defaultMessage: "Value",
  },
  tournaments: {
    id: "tournaments",
    defaultMessage: "Tournaments",
  },
  streamspace: {
    id: "streamspace",
    defaultMessage: "Streamspace",
  },
  empty: {
    id: "empty",
    defaultMessage: "Empty",
  },
  default_message: {
    id: "default_message",
    defaultMessage: "Default message",
  },
  translation: {
    id: "translation",
    defaultMessage: "Translation",
  },
  edit: {
    id: "edit",
    defaultMessage: "Edit",
  },
  casino_promo: {
    id: "casino_promo",
    defaultMessage: "Casino Promo",
  },
  conversion: {
    id: "conversion",
    defaultMessage: "Conversion",
  },
  day: {
    id: "day",
    defaultMessage: "Day",
  },
  week: {
    id: "week",
    defaultMessage: "Week",
  },
  month: {
    id: "month",
    defaultMessage: "Month",
  },
  create_user: {
    id: "create_user",
    defaultMessage: "Create user",
  },
  successfully_created: {
    id: "successfully_created",
    defaultMessage: "User successfully created",
  },
  play_now: {
    id: "play_now",
    defaultMessage: "Play now",
  },
  button: {
    id: "button",
    defaultMessage: "Button",
  },
  about_us_title: {
    id: "about_us_title",
    defaultMessage: "About Project",
  },
  about_item1: {
    id: "about_item1",
    defaultMessage: "Full casino and sportsbook products",
  },
  about_item2: {
    id: "about_item2",
    defaultMessage: "High-quality responsive website",
  },
  about_item3: {
    id: "about_item3",
    defaultMessage: "Platform",
  },
  about_item4: {
    id: "about_item4",
    defaultMessage: "Most popular casino and game providers",
  },
  about_item5: {
    id: "about_item5",
    defaultMessage: "Global payment systems",
  },
  about_item6: {
    id: "about_item6",
    defaultMessage: "Curacao Licence",
  },
  about_item7: {
    id: "about_item7",
    defaultMessage: "New players get 100% of their first deposit back",
  },
  rent_app: {
    id: "rent_app",
    defaultMessage: "Rent application",
  },
  become_partner: {
    id: "become_partner",
    defaultMessage: "Want to be our partner?",
  },
  become_partner_text: {
    id: "become_partner_text",
    defaultMessage:
      "If you own youtube, twitch or personal website, join us and contact our managers",
  },
  speed_banner: {
    id: "speed_banner",
    defaultMessage: "Speed. Reliability. Accuracy.",
  },
  speed_banner_text: {
    id: "speed_banner_text",
    defaultMessage:
      "Super flexible commissions constructor allowing creating any type of a deal.",
  },
  forgot_password: {
    id: "forgot_password",
    defaultMessage: "Forgot your password?",
  },
  promo_archive: {
    id: "promo_archive",
    defaultMessage: "Archive",
  },
  promo_order: {
    id: "promo_order",
    defaultMessage: "Order",
  },
  managers: {
    id: "managers",
    defaultMessage: "Managers",
  },
  reports: {
    id: "reports",
    defaultMessage: "Reports",
  },
  affiliates: {
    id: "affiliates",
    defaultMessage: "Affiliates report",
  },
  partner_id: {
    id: "partner_id",
    defaultMessage: "Partner ID",
  },
  role: {
    id: "role",
    defaultMessage: "Role",
  },
  balance: {
    id: "balance",
    defaultMessage: "Balance",
  },
  casino_profit: {
    id: "casino_profit",
    defaultMessage: "Casino profit",
  },
  assured_income: {
    id: "assured_income",
    defaultMessage: "Assured income",
  },
  affiliates_report: {
    id: "affiliates_report",
    defaultMessage: "Affiliates report",
  },
  slide_1_title: {
    id: "slide_1_title",
    defaultMessage: "GOLD RUSH {divider} CASINO Affiliate",
  },
  slide_1_title_2: {
    id: "slide_1_title_2",
    defaultMessage: "No.1 affiliate program in the industry",
  },
  slide_1_text: {
    id: "slide_1_text",
    defaultMessage:
      "Gold Rush Casino is a casino site with many games that cannot be played in the Asian market. Experience the best affiliate programs in the industry at our casino. We strictly adhere to the trust and alliances with our affiliates and strive for mutual success.",
  },
  slide_2_title: {
    id: "slide_2_title",
    defaultMessage: "Virtual Sports 24/7",
  },
  slide_2_text: {
    id: "slide_2_text",
    defaultMessage: "With our Daily Reload Bonus",
  },
  time_ago_title: {
    id: "time_ago_title",
    defaultMessage: "Time ago",
  },
  description: {
    id: "description",
    defaultMessage: "Description",
  },
  sort_order: {
    id: "sort_order",
    defaultMessage: "Sort order",
  },
  is_recommend: {
    id: "is_recommend",
    defaultMessage: "Is recommend",
  },
  copy: {
    id: "copy",
    defaultMessage: "Copy",
  },
  program_option_check_description: {
    id: "program_option_check_description",
    defaultMessage:
      "Required Rounds or not (if this options enable CPA calculated from cpa_required_deposits, else from cpa_required_ng, if it absent, rounds_accumulated >= 20 )",
  },
  program_option_check_description_label: {
    id: "program_option_check_description_label",
    defaultMessage: "Required Round for CPA reward",
  },
  table_title_affiliate_programs: {
    id: "table_title_affiliate_programs",
    defaultMessage: "Available affiliate programs",
  },
  show_all: {
    id: "show_all",
    defaultMessage: "Show all",
  },
  show_public: {
    id: "show_public",
    defaultMessage: "Show public",
  },
  actions: {
    id: "actions",
    defaultMessage: "Actions",
  },
  default_landing_checkbox: {
    id: "default_landing_checkbox",
    defaultMessage: "Is default Landing for this project",
  },
  lang_english: {
    id: "lang_english",
    defaultMessage: "English",
  },
  lang_japanese: {
    id: "lang_japanese",
    defaultMessage: "Japanese",
  },
  lang_turkish: {
    id: "lang_turkish",
    defaultMessage: "Turkish",
  },
  lang_portuguese: {
    id: "lang_portuguese",
    defaultMessage: "Portuguese",
  },
  title: {
    id: "title",
    defaultMessage: "Title",
  },
  options_cpa_reward: {
    id: "options_cpa_reward",
    defaultMessage: "CPA Reward",
  },
  options_description_cpa_reward: {
    id: "options_description_cpa_reward",
    defaultMessage: "CPA reward",
  },
  options_cpa_required_deposits: {
    id: "options_cpa_required_deposits",
    defaultMessage: "CPA Required Deposits",
  },
  options_description_cpa_required_deposits: {
    id: "options_description_cpa_required_deposits",
    defaultMessage: "Sum Deposits for CPA reward",
  },
  options_cpa_required_first_deposit: {
    id: "options_cpa_required_first_deposit",
    defaultMessage: "CPA Required First Deposits",
  },
  options_description_cpa_required_first_deposit: {
    id: "options_description_cpa_required_first_deposit",
    defaultMessage: "Sum First Deposit for CPA reward",
  },
  options_cpa_required_ng: {
    id: "options_cpa_required_ng",
    defaultMessage: "CPA Required NG",
  },
  options_description_cpa_required_ng: {
    id: "options_description_cpa_required_ng",
    defaultMessage: "Sum NG for CPA reward, if no_rounds_required is disabled",
  },
  options_cpa_var1: {
    id: "options_cpa_var1",
    defaultMessage: "CPA var1",
  },
  options_description_cpa_var1: {
    id: "options_description_cpa_var1",
    defaultMessage: "Alternative value for CPA Required Deposits",
  },
  options_cpa_var2: {
    id: "options_cpa_var2",
    defaultMessage: "CPA var2",
  },
  options_description_cpa_var2: {
    id: "options_description_cpa_var2",
    defaultMessage: "Alternative value for CPA Required First Deposits",
  },
  options_cpa_escaping: {
    id: "options_cpa_escaping",
    defaultMessage: "Count of days to get CPA qualification",
  },
  options_description_cpa_escaping: {
    id: "options_description_cpa_escaping",
    defaultMessage: "Count of days from registration to get CPA qualification",
  },
  options_cpa_allowed_countries: {
    id: "options_cpa_allowed_countries",
    defaultMessage: "Allowed countries",
  },
  options_description_cpa_allowed_countries: {
    id: "options_description_cpa_allowed_countries",
    defaultMessage: "* This field required format: 'US', 'EN', 'CH'",
  },
  options_revshare_reward: {
    id: "options_revshare_reward",
    defaultMessage: "Revshare Reward, %",
  },
  options_description_revshare_reward: {
    id: "options_description_revshare_reward",
    defaultMessage:
      "Percents of casino income. Required for RevShare and Hybrid programs",
  },
  options_transaction_commission: {
    id: "options_transaction_commission",
    defaultMessage: "Transaction Commission, %",
  },
  options_description_transaction_commission: {
    id: "options_description_transaction_commission",
    defaultMessage: "Transaction commission. Default = 5%",
  },
  options_royalty: {
    id: "options_royalty",
    defaultMessage: "Royalty, %",
  },
  options_description_royalty: {
    id: "options_description_royalty",
    defaultMessage: "Percents of royalty for income. Default = 0%",
  },
  options_revshare_fi: {
    id: "options_revshare_fi",
    defaultMessage: "FI, %",
  },
  options_description_revshare_fi: {
    id: "options_description_revshare_fi",
    defaultMessage:
      "Percent of ng we uses to calculate partner_income (ng * :fi - comppoints). Default = 100%",
  },
  options_old_gamers_reward: {
    id: "options_old_gamers_reward",
    defaultMessage: "Old Gamers Reward, %",
  },
  options_description_old_gamers_reward: {
    id: "options_description_old_gamers_reward",
    defaultMessage: "Reward for old gamers",
  },
  options_new_gamers_reward: {
    id: "options_new_gamers_reward",
    defaultMessage: "New Gamers Reward, %",
  },
  options_description_new_gamers_reward: {
    id: "options_description_new_gamers_reward",
    defaultMessage: "Reward for new gamers",
  },
  options_new_gamers_lifetime: {
    id: "options_new_gamers_lifetime",
    defaultMessage: "New Gamers lifetime, count",
  },
  options_description_new_gamers_lifetime: {
    id: "options_description_new_gamers_lifetime",
    defaultMessage:
      "The number of days in which the player is considered a new",
  },
  options_old_gamers_lifetime: {
    id: "options_old_gamers_lifetime",
    defaultMessage: "Old Gamers lifetime, count",
  },
  options_description_old_gamers_lifetime: {
    id: "options_description_old_gamers_lifetime",
    defaultMessage: "The number of days after witch gamer become old",
  },
  options_old_gamers_reward_start_date: {
    id: "options_old_gamers_reward_start_date",
    defaultMessage: "Old gamer reward start date, date",
  },
  options_description_old_gamers_reward_start_date: {
    id: "options_description_old_gamers_reward_start_date",
    defaultMessage: "Date after witch old gamer logic began work",
  },
  options_low_bonus_rate: {
    id: "options_low_bonus_rate",
    defaultMessage: "Minimum bonus rate, %",
  },
  options_description_low_bonus_rate: {
    id: "options_description_low_bonus_rate",
    defaultMessage: "Minimum bonus rate to get old gamers reward",
  },
  image: {
    id: "image",
    defaultMessage: "Image",
  },
  upload_logo: {
    id: "upload_logo",
    defaultMessage: "Upload logo",
  },
  edit_project: {
    id: "edit_project",
    defaultMessage: "Edit project",
  },
  edit_program: {
    id: "edit_program",
    defaultMessage: "Edit program for",
  },
  create_program: {
    id: "create_program",
    defaultMessage: "Create program for",
  },
  add_landing: {
    id: "add_landing",
    defaultMessage: "Add landing page",
  },
  add_program: {
    id: "add_program",
    defaultMessage: "Add program",
  },
  reset: {
    id: "reset",
    defaultMessage: "Reset",
  },
  error_404_title: {
    id: "error_404_title",
    defaultMessage: "Page not found",
  },
  error_404_text: {
    id: "error_404_text",
    defaultMessage:
      "You may have mistyped the address or the page may have moved",
  },
  error_403_title: {
    id: "error_403_title",
    defaultMessage: "Forbidden",
  },
  error_403_text: {
    id: "error_404_text",
    defaultMessage: "Access to this resource on the server is denied!",
  },
  back_to_main: {
    id: "back_to_main",
    defaultMessage: "Back to the Main Page",
  },
  user_not_activated: {
    id: "user_not_activated",
    defaultMessage: "Not activated",
  },
  email_verified: {
    id: "email_verified",
    defaultMessage: "Email verified",
  },
  is_promo: {
    id: "is_promo",
    defaultMessage: "Is promo",
  },
  program_types: {
    id: "program_types",
    defaultMessage: "Program types",
  },
  input_title: {
    id: "input_title",
    defaultMessage: "Please input title",
  },
  successfully_created_msg: {
    id: "successfully_created_msg",
    defaultMessage: "Successfully created",
  },
  have_account: {
    id: "have_account",
    defaultMessage: "Already have an account?",
  },
  dont_have_account: {
    id: "dont_have_account",
    defaultMessage: "Dont have an account?",
  },
  chats_title: {
    id: "chats_title",
    defaultMessage: "Chat",
  },
  no_new_msg_title: {
    id: "no_new_msg_title",
    defaultMessage: "You dont have any messages yet",
  },
  new_msg_title: {
    id: "new_msg_title",
    defaultMessage: "New unread messages",
  },
  conversations_title: {
    id: "conversations_title",
    defaultMessage: "Your conversations",
  },
  chat_close: {
    id: "chat_close",
    defaultMessage: "Close chat",
  },
  chat_reopen: {
    id: "chat_reopen",
    defaultMessage: "Reopen chat",
  },
  default: {
    id: "default",
    defaultMessage: "Default",
  },
  priority: {
    id: "priority",
    defaultMessage: "Priority",
  },
  administrator: {
    id: "administrator",
    defaultMessage: "Administrator",
  },
  low: {
    id: "low",
    defaultMessage: "Low",
  },
  high: {
    id: "high",
    defaultMessage: "High",
  },
  medium: {
    id: "medium",
    defaultMessage: "Medium",
  },
  edit_msg: {
    id: "edit_msg",
    defaultMessage: "Edit message",
  },
  enter_msg: {
    id: "enter_msg",
    defaultMessage: "Enter your message",
  },
  send_mail: {
    id: "send_mail",
    defaultMessage: "Send mail",
  },
  enter_partner: {
    id: "enter_partner",
    defaultMessage: "Enter partner ID / Login",
  },
  inbox: {
    id: "inbox",
    defaultMessage: "Inbox",
  },
  outbox: {
    id: "outbox",
    defaultMessage: "Outbox",
  },
  archive: {
    id: "archive",
    defaultMessage: "Archive",
  },
  create_new_msg: {
    id: "create_new_msg",
    defaultMessage: "Create new message",
  },
  theme: {
    id: "theme",
    defaultMessage: "Theme",
  },
  category: {
    id: "category",
    defaultMessage: "Category",
  },
  receiver: {
    id: "receiver",
    defaultMessage: "Receiver",
  },
  message: {
    id: "message",
    defaultMessage: "Message",
  },
  input_msg_error_1: {
    id: "input_msg_error_1",
    defaultMessage: "Please enter your message!",
  },
  input_msg_error_2: {
    id: "input_msg_error_2",
    defaultMessage: "Please input more than 3 characters!",
  },
  title_filter_msg: {
    id: "title_filter_msg",
    defaultMessage: "Filter Messages",
  },
  sort_by: {
    id: "sort_by",
    defaultMessage: "Sort by",
  },
  search_partner_title: {
    id: "search_partner_title",
    defaultMessage: "Partner ID / Login",
  },
  marked_only: {
    id: "marked_only",
    defaultMessage: "Marked only",
  },
  new_msg_send: {
    id: "new_msg_send",
    defaultMessage: "New message was send",
  },
  new_options_applied: {
    id: "new_options_applied",
    defaultMessage: "New options applied",
  },
  special_option_form_amount_description: {
    id: "special_option_form_amount_description",
    defaultMessage: "Amount description text",
  },
  project_name: {
    id: "project_name",
    defaultMessage: "Project name",
  },
  manage_notifications: {
    id: "manage_notifications",
    defaultMessage: "Manage notifications",
  },
  create_new_notification: {
    id: "create_new_notification",
    defaultMessage: "Create new notification",
  },
  notification_placeholder_title: {
    id: "notification_placeholder_title",
    defaultMessage: "Notification title",
  },
  notification_placeholder_text: {
    id: "notification_placeholder_text",
    defaultMessage: "Notification text",
  },
  notification_card_content_title: {
    id: "notification_card_content_title",
    defaultMessage: "Notification text",
  },
  notification_card_other_settings_title: {
    id: "notification_card_other_settings_title",
    defaultMessage: "Other settings",
  },
  notification_card_style_select: {
    id: "notification_card_style_select",
    defaultMessage: "Notification style",
  },
  notification_card_create_title: {
    id: "notification_card_create_title",
    defaultMessage: "Create new notification",
  },
  translation_field_required: {
    id: "translation_field_required",
    defaultMessage: "Fill the translation field",
  },
  always_show_notification: {
    id: "always_show_notification",
    defaultMessage: "Always show notification",
  },
  notification_date_range: {
    id: "notification_date_range",
    defaultMessage: "Choose notification shown date range",
  },
  is_notification_active: {
    id: "is_notification_active",
    defaultMessage: "Is notification Active",
  },
  same_text_for_fields: {
    id: "same_text_for_fields",
    defaultMessage: "Same text for all fields",
  },
  notification_show_period: {
    id: "notification_show_period",
    defaultMessage: "Show period",
  },
  display_always: {
    id: "display_always",
    defaultMessage: "Always",
  },
  show_notification_at_days: {
    id: "show_notification_at_days",
    defaultMessage: "Days from - to",
  },
  delete_notification: {
    id: "delete_notification",
    defaultMessage: "Delete notification",
  },
  restore: {
    id: "restore",
    defaultMessage: "Restore",
  },
  show_deleted_notifications: {
    id: "show_deleted_notifications",
    defaultMessage: "Show deleted notifications",
  },
  successfully_sent: {
    id: "successfully_sent",
    defaultMessage: "Successfully sent",
  },
  clear_all_notifications: {
    id: "clear_all_notifications",
    defaultMessage: "Clear notifications",
  },
  Notifications: {
    id: "Notifications",
    defaultMessage: "Notifications",
  },
  add_to_list: {
    id: "add_to_list",
    defaultMessage: "Add to list",
  },
  user_list: {
    id: "user_list",
    defaultMessage: "Users",
  },
  manager_list: {
    id: "manager_list",
    defaultMessage: "Managers",
  },
  delete: {
    id: "delete",
    defaultMessage: "Delete",
  },
  deleted_successfully: {
    id: "deleted_successfully",
    defaultMessage: "Deleted successfully",
  },
  manageable_list: {
    id: "manageable_list",
    defaultMessage: "Manageable list",
  },
  required_field: {
    id: "required_field",
    defaultMessage: "This field is required",
  },
  add_user_to_list: {
    id: "add_user_to_list",
    defaultMessage: "Add user to manageable list",
  },
  add_manager_to_list: {
    id: "add_manager_to_list",
    defaultMessage: "Add manager to manageable list",
  },
  create_new_manageable_list: {
    id: "create_new_manageable_list",
    defaultMessage: "Create mew manageable list",
  },
  show_users: {
    id: "show_users",
    defaultMessage: "Show users",
  },
  hide_users: {
    id: "hide_users",
    defaultMessage: "Hide users",
  },
  gamers_ftd_report: {
    id: "gamers_ftd_report",
    defaultMessage: "Gamers FTD report",
  },
  gamers_report: {
    id: "gamers_report",
    defaultMessage: "Gamers report",
  },
  gamer_id: {
    id: "gamer_id",
    defaultMessage: "Gamer ID",
  },
  project_id: {
    id: "project_id",
    defaultMessage: "Project ID",
  },
  first_deposit_date: {
    id: "first_deposit_date",
    defaultMessage: "1st deposit date",
  },
  first_deposit: {
    id: "first_deposit",
    defaultMessage: "1st deposit",
  },
  deposits_sum: {
    id: "deposits_sum",
    defaultMessage: "Deposits sum",
  },
  deposits_count: {
    id: "deposits_count",
    defaultMessage: "Deposits count",
  },
  reg_ip: {
    id: "reg_ip",
    defaultMessage: "Reg IP",
  },
  country: {
    id: "country",
    defaultMessage: "Country",
  },
  bonuses: {
    id: "bonuses",
    defaultMessage: "Bonuses",
  },
  duplicates: {
    id: "duplicates",
    defaultMessage: "Duplicates",
  },
  partner_login: {
    id: "partner_login",
    defaultMessage: "Partner login",
  },
  status_label: {
    id: "status_label",
    defaultMessage: "Status",
  },
  role_label: {
    id: "role_label",
    defaultMessage: "Role",
  },
  traffic_type_label: {
    id: "traffic_type_label",
    defaultMessage: "Traffic type",
  },
  cash_type_label: {
    id: "cash_type_label",
    defaultMessage: "Cash type",
  },
  hits: {
    id: "hits",
    defaultMessage: "Hits",
  },
  hosts: {
    id: "hosts",
    defaultMessage: "Hosts",
  },
  rounds: {
    id: "rounds",
    defaultMessage: "Rounds",
  },
  qualified_players: {
    id: "qualified_players",
    defaultMessage: "Qualified players",
  },
  ng: {
    id: "ng",
    defaultMessage: "NG",
  },
  withdraws: {
    id: "withdraws",
    defaultMessage: "Withdraws",
  },
  comppoints: {
    id: "comppoints",
    defaultMessage: "Comppoints",
  },
  promo_id: {
    id: "promo_id",
    defaultMessage: "Promo ID",
  },
  program_id: {
    id: "program_id",
    defaultMessage: "Program ID",
  },
  qualification_date: {
    id: "qualification_date",
    defaultMessage: "Qualification date",
  },
  day_from: {
    id: "day_from",
    defaultMessage: "Day from",
  },
  day_to: {
    id: "day_to",
    defaultMessage: "Day to",
  },
  bonus_income_with_currency_symbol: {
    id: "bonus_income_with_currency_symbol",
    defaultMessage: "Partner income",
  },
  partner_income_with_currency_symbol: {
    id: "partner_income_with_currency_symbol",
    defaultMessage: "Partner income",
  },
  full_income_with_currency_symbol: {
    id: "full_income_with_currency_symbol",
    defaultMessage: "Full income",
  },
  ng_with_currency_symbol: {
    id: "ng_with_currency_symbol",
    defaultMessage: "Net gaming",
  },
  deposits_with_currency_symbol: {
    id: "deposits_with_currency_symbol",
    defaultMessage: "Deposits",
  },
  withdraws_with_currency_symbol: {
    id: "withdraws_with_currency_symbol",
    defaultMessage: "Withdraws",
  },
  first_deposit_with_currency_symbol: {
    id: "first_deposit_with_currency_symbol",
    defaultMessage: "1st Deposit",
  },
  benefit_point_title_1: {
    id: "benefit_point_title_1",
    defaultMessage: "Enjoy the best gaming experience",
  },
  benefit_point_text_1: {
    id: "benefit_point_text_1",
    defaultMessage:
      "At Gold Rush Casino, players can enjoy the best gaming experience in the safest and most secure playing environment. {divider}Earn high income by participating in an affiliate program that can promote such a gold rush casino.",
  },
  benefit_point_title_2: {
    id: "benefit_point_title_2",
    defaultMessage: "No negative balance!",
  },
  benefit_point_text_2: {
    id: "benefit_point_text_2",
    defaultMessage:
      "It will be reset to zero the following month, so even if you have an unlucky month, it will not affect your income in the next month.",
  },
  benefit_point_title_3: {
    id: "benefit_point_title_3",
    defaultMessage: "Manage the registration",
  },
  benefit_point_text_3: {
    id: "benefit_point_text_3",
    defaultMessage:
      "You can manage the registration status, activity status, rewards, etc. of the player collectively.{divider} Let's earn high income in a safe and secure business environment!",
  },
  free_code_title: {
    id: "free_code_title",
    defaultMessage: "Free code",
  },
  free_code_text: {
    id: "free_code_text",
    defaultMessage:
      "Once you have an affiliate account, you get a free affiliate code and banner to start promoting your site!",
  },
  free_code_btn_text: {
    id: "free_code_btn_text",
    defaultMessage: "Use it now!",
  },
  protect_msg: {
    id: "protect_msg",
    defaultMessage:
      "This site is operated by Abudantia B.V., a company registered under the laws of Curacao under registration number 126608 and having its registered address at Heelsumstraat 51 E-Commerce Park, Curacao. Abudantia B.V. holds a sublicense # 8048/JAZ2014-034 issued by Antillephone N.V., which has been established under the laws of Curaçao and holds a Gaming License # 8048/JAZ.",
  },
  our_testimonials: {
    id: "our_testimonials",
    defaultMessage: "Our testimonials",
  },
  create_new_testimonial: {
    id: "create_new_testimonial",
    defaultMessage: "Create new testimonial",
  },
  testimonial_create_name: {
    id: "testimonial_create_name",
    defaultMessage: "Create new Testimonial",
  },
  testimonial_card_content_title: {
    id: "testimonial_card_content_title",
    defaultMessage: "Testimonial information",
  },
  testimonial_placeholder_name: {
    id: "testimonial_placeholder_name",
    defaultMessage: "Name",
  },
  testimonial_card_style_select: {
    id: "testimonial_card_style_select",
    defaultMessage: "Priority",
  },
  testimonial_placeholder_sort: {
    id: "testimonial_placeholder_sort",
    defaultMessage: "Sort order",
  },
  is_testimonial_visible: {
    id: "is_testimonial_visible",
    defaultMessage: "Visible",
  },
  testimonials_card_languages_title: {
    id: "testimonials_card_languages_title",
    defaultMessage: "Translations",
  },
  testimonial_placeholder_title: {
    id: "testimonial_placeholder_title",
    defaultMessage: "Title",
  },
  testimonial_placeholder_text: {
    id: "testimonial_placeholder_text",
    defaultMessage: "Text",
  },
  testimonial_placeholder_content: {
    id: "testimonial_placeholder_content",
    defaultMessage: "Content",
  },
  invited_with: {
    id: "invited_with",
    defaultMessage: "Invited With",
  },
  translation_card_title: {
    id: "translation_card_title",
    defaultMessage: "Translation",
  },
  create_new_translation: {
    id: "create_new_translation",
    defaultMessage: "Create new translation key",
  },
  translation_card_key: {
    id: "translation_card_key",
    defaultMessage: "Translation Key",
  },
  translation_placeholder_default_message: {
    id: "translation_placeholder_default_message",
    defaultMessage: "Default message",
  },
  default_message_field_required: {
    id: "default_message_field_required",
    defaultMessage: "Default message field is required",
  },
  translation_card_languages_title: {
    id: "translation_card_languages_title",
    defaultMessage: "Translations",
  },
  translation_placeholder_translation: {
    id: "translation_placeholder_translation",
    defaultMessage: "Translation",
  },
  translation_key_create_name: {
    id: "translation_key_create_name",
    defaultMessage: "Translation Key",
  },
  translation_key_card_title: {
    id: "translation_key_card_title",
    defaultMessage: "Translation Key creation",
  },
  translation_key_field_required: {
    id: "translation_key_field_required",
    defaultMessage: "Translation Key required",
  },
  translation_key_placeholder_key: {
    id: "translation_key_placeholder_key",
    defaultMessage: "Key",
  },
  translation_key_placeholder_default_message: {
    id: "translation_key_placeholder_default_message",
    defaultMessage: "Default message",
  },
  create_new_faq: {
    id: "create_new_faq",
    defaultMessage: "Create new FAQ",
  },
  faq_create_title: {
    id: "faq_create_title",
    defaultMessage: "Create new FAQ",
  },
  faq_edit_title: {
    id: "faq_edit_title",
    defaultMessage: "Edit FAQ",
  },
  faq_category_select: {
    id: "faq_category_select",
    defaultMessage: "Select Category",
  },
  faq_card_languages_title: {
    id: "faq_card_languages_title",
    defaultMessage: "FAQ translations",
  },
  faq_placeholder_question: {
    id: "faq_placeholder_question",
    defaultMessage: "Question",
  },
  question_field_required: {
    id: "question_field_required",
    defaultMessage: "Question field is required",
  },
  faq_placeholder_answer: {
    id: "faq_placeholder_answer",
    defaultMessage: "Answer",
  },
  answer_field_required: {
    id: "answer_field_required",
    defaultMessage: "Answer field is required",
  },
  admin_dashboard: {
    id: "admin_dashboard",
    defaultMessage: "Admin Dashboard",
  },
  admin_dashboard_top_users: {
    id: "admin_dashboard_top_users",
    defaultMessage: "Top users",
  },
  manage_users: {
    id: "manage_users",
    defaultMessage: "Manage users",
  },
  geo_report: {
    id: "geo_report",
    defaultMessage: "Geo report",
  },
  geo_data: {
    id: "geo_data",
    defaultMessage: "Geo data",
  },
  hosts_diff: {
    id: "hosts_diff",
    defaultMessage: "Hosts diff.",
  },
  hosts_rel_diff: {
    id: "hosts_rel_diff",
    defaultMessage: "Hosts rel diff.",
  },
  in_out1: {
    id: "in_out1",
    defaultMessage: "In / Out 1",
  },
  in_out2: {
    id: "in_out2",
    defaultMessage: "In / Out 2",
  },
  in_out_diff: {
    id: "in_out_diff",
    defaultMessage: "In / Out diff.",
  },
  in_out_rel_diff: {
    id: "in_out_rel_diff",
    defaultMessage: "In / Out rel diff.",
  },
  ngcp1: {
    id: "ngcp1",
    defaultMessage: "NGCP 1",
  },
  ngcp2: {
    id: "ngcp2",
    defaultMessage: "NGCP 2",
  },
  ngcp_diff: {
    id: "ngcp_diff",
    defaultMessage: "NGCP diff.",
  },
  ngcp_rel_diff: {
    id: "ngcp_rel_diff",
    defaultMessage: "NGCP rel diff.",
  },
  partner_income1: {
    id: "partner_income1",
    defaultMessage: "Partner income 1",
  },
  partner_income2: {
    id: "partner_income2",
    defaultMessage: "Partner income 2",
  },
  partner_income_diff: {
    id: "partner_income_diff",
    defaultMessage: "Partner income diff.",
  },
  partner_income_rel_diff: {
    id: "partner_income_rel_diff",
    defaultMessage: "Partner income rel diff.",
  },
  regs1: {
    id: "regs1",
    defaultMessage: "Regs 1",
  },
  regs2: {
    id: "regs2",
    defaultMessage: "Regs 2",
  },
  regs_diff: {
    id: "regs_diff",
    defaultMessage: "Regs diff.",
  },
  regs_rel_diff: {
    id: "regs_rel_diff",
    defaultMessage: "Regs rel diff.",
  },
  role_text: {
    id: "role_text",
    defaultMessage: "Role",
  },
  affiliate_difference_report: {
    id: "affiliate_difference_report",
    defaultMessage: "Affiliate difference report",
  },

  bets: {
    id: "bets",
    defaultMessage: "Bets",
  },
  promo_code: {
    id: "promo_code",
    defaultMessage: "Promo code",
  },
  promo_name: {
    id: "promo_name",
    defaultMessage: "Promo name",
  },

  program_name: {
    id: "program_name",
    defaultMessage: "Program name",
  },
  withdraws_converted: {
    id: "withdraws_converted",
    defaultMessage: "Withdraws converted",
  },
  deposits_converted: {
    id: "deposits_converted",
    defaultMessage: "Deposits converted",
  },
  ng_converted: {
    id: "ng_converted",
    defaultMessage: "Net gaming converted",
  },
  cancels: {
    id: "cancels",
    defaultMessage: "Cancels",
  },
  cancels_converted: {
    id: "cancels_converted",
    defaultMessage: "Cancels converted",
  },
  comppoints_converted: {
    id: "comppoints_converted",
    defaultMessage: "Comppoints converted",
  },
  casino_income: {
    id: "casino_income",
    defaultMessage: "Casino income",
  },
  casino_income_converted: {
    id: "casino_income_converted",
    defaultMessage: "Casino income converted",
  },
  partner_income_converted: {
    id: "partner_income_converted",
    defaultMessage: "Partner income converted",
  },
  installs: {
    id: "installs",
    defaultMessage: "Installs",
  },
  regs_all: {
    id: "regs_all",
    defaultMessage: "Regs all",
  },
  assured_income_converted: {
    id: "assured_income_converted",
    defaultMessage: "Assured income converted",
  },
  currency_select: {
    id: "currency_select",
    defaultMessage: "Currency",
  },
  currency_select_placeholder: {
    id: "currency_select_placeholder",
    defaultMessage: "Please select currency",
  },
  multi_account: {
    id: "multi_account",
    defaultMessage: "Multi accounts",
  },
  multiacc_count: {
    id: "multiacc_count",
    defaultMessage: "Multi accounts count",
  },
  rd: {
    id: "rd",
    defaultMessage: "RD",
  },
  romi: {
    id: "romi",
    defaultMessage: "ROMI",
  },
  first_deposits1: {
    id: "first_deposits1",
    defaultMessage: "First dep. 1",
  },
  first_deposits2: {
    id: "first_deposits2",
    defaultMessage: "First dep. 2",
  },
  first_deposits_diff: {
    id: "first_deposits_diff",
    defaultMessage: "First dep. diff.",
  },
  first_deposits_rel_diff: {
    id: "first_deposits_rel_diff",
    defaultMessage: "First deposits rel diff.",
  },
  hosts1: {
    id: "hosts1",
    defaultMessage: "Hosts 1",
  },
  hosts2: {
    id: "hosts2",
    defaultMessage: "Hosts 2",
  },
  hold_converted: {
    id: "hold_converted",
    defaultMessage: "Hold converted",
  },
  status_text: {
    id: "status_text",
    defaultMessage: "Status",
  },
  import_translations: {
    id: "import_translations",
    defaultMessage: "Import Translations",
  },
  excel_file: {
    id: "excel_file",
    defaultMessage: "Select Excel File",
  },
  translation_file_field_required: {
    id: "translation_file_field_required",
    defaultMessage: "Translation file is required",
  },
  affiliates_geo_report: {
    id: "affiliates_geo_report",
    defaultMessage: "Affiliates geo report",
  },
  rd_percent: {
    id: "rd_percent",
    defaultMessage: "RD percent",
  },
  affiliate_geo_details: {
    id: "affiliate_geo_details",
    defaultMessage: "Details info",
  },
  country_full: {
    id: "country_full",
    defaultMessage: "Country",
  },
};
