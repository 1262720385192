import React from "react";
import config from "../../../system/config";
import { _t } from "../../Common/components/InjectIntlContext";

const partnersList = [
  {
    icon: `${config.projectImages}/partners/astroPay.svg`,
    title: "AstroPay",
  },
  {
    icon: `${config.projectImages}/partners/Bitcoin.svg`,
    title: "Bitcoin",
  },
  {
    icon: `${config.projectImages}/partners/ecoPayz.svg`,
    title: "ecoPayz",
  },
  {
    icon: `${config.projectImages}/partners/Visa.svg`,
    title: "Visa",
  },
  {
    icon: `${config.projectImages}/partners/Mastercard.svg`,
    title: "Mastercard",
  },
  {
    icon: `${config.projectImages}/partners/Skrill.svg`,
    title: "Skrill",
  },
  {
    icon: `${config.projectImages}/partners/Neteller.svg`,
    title: "Neteller",
  },
  {
    icon: `${config.projectImages}/partners/Multibanco.svg`,
    title: "Multibanco",
  },
  {
    icon: `${config.projectImages}/partners/netent.svg`,
    title: "netent",
  },
  {
    icon: `${config.projectImages}/partners/evo-games.svg`,
    title: "evo-games",
  },
  {
    icon: `${config.projectImages}/partners/betsogt.svg`,
    title: "betsogt",
  },
  {
    icon: `${config.projectImages}/partners/ezugi.svg`,
    title: "ezugi",
  },
  {
    icon: `${config.projectImages}/partners/playson.svg`,
    title: "playson",
  },
  {
    icon: `${config.projectImages}/partners/pragmatic.svg`,
    title: "pragmatic",
  },
  {
    icon: `${config.projectImages}/partners/quckfire.svg`,
    title: "quckfire",
  },
];
const Partners = () => {
  return (
    <div className="partners">
      <div className="container">
        <div className="partners__wrap">
          <div className="partners-list">
            {partnersList.map((item, idx) => (
              <img
                src={item.icon}
                alt={item.title}
                title={item.title}
                key={item.title}
              />
            ))}
          </div>
          <div className="partner-link">
            <a href="https://oncasitown.com/" rel="noreferrer noopener" target="_blank">
              <img
                src={`${config.projectImages}/partners/oncasitown.png`}
                alt=""
              />
            </a>
            <a href="https://majan.co.jp/media/" rel="noreferrer noopener" target="_blank">
              <img
                src={`${config.projectImages}/partners/banner_top10.png`}
                alt=""
              />
            </a>
            <a href="https://www.kakutospirit.net" rel="noreferrer noopener" target="_blank">
              <img
                src={`${config.projectImages}/partners/banner_spirit.png`}
                alt=""
              />
            </a>
          </div>
          <div className="protect-msg">
            <img src={`${config.projectImages}/protect-icon.svg`} alt=""/>
            {_t("protect_msg")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
