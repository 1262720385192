import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BannerSlide from "./BannerSlide";
import { _t } from "../../Common/components/InjectIntlContext";
import { onGetStarted } from "../constants";
import config from "../../../system/config";

export type mainBannerSliderProps = {};
const bannerSlides = (_t: any) => [
  {
    backgroundImg: `${config.projectImages}/banners/banner_1.jpg`,
    title: _t("slide_1_title", {divider: "<br/>", highlight_open: "<span>", highlight_close: "</span>", } ),
    title_2: _t("slide_1_title_2"),
    subtitle: _t("slide_1_text", {divider: "<br/>"}),
    btnLabel: _t("get_started"),
    onClick: () => onGetStarted(),
  },
  {
    backgroundImg: `${config.projectImages}/banners/banner_2.jpg`,
    title: _t("slide_2_title"),
    subtitle: _t("slide_2_text"),
    btnLabel: _t("get_started"),
    onClick: () => onGetStarted(),
  },
  // {
  //   backgroundImg: banner_3,
  //   title: _t("slide_3_title"),
  //   subtitle: _t("slide_3_text"),
  //   btnLabel: _t("get_started"),
  //   onClick: () => onGetStarted(),
  // },
];

const slickSettings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 8000,
  fade: true,
  adaptiveHeight: true,
};

const MainBannerSlider: React.FC<mainBannerSliderProps> = () => {
  const slide = (slide, idx) => <BannerSlide info={slide} key={idx + "bs"} />;
  return (
    <div className="main-banner">
      <Slider {...slickSettings}>{bannerSlides(_t).map(slide)}</Slider>
    </div>
  );
};

export default MainBannerSlider;
