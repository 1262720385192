import React from "react";
import { _t } from "../../../Common/components/InjectIntlContext";
import CommissionPage from "./CommissionPage";
import config from "../../../../system/config";

const SubAffiliate = () => {
  const points = (_t: any) => [
    _t("subaffiliate_1"),
    _t("subaffiliate_2"),
    _t("subaffiliate_3"),
    _t("subaffiliate_4"),
  ];
  const title = _t("subaffiliate_title");
  return <CommissionPage title={title} icon={`${config.projectImages}/commissions/image_3.svg`} items={points} />;
};

export default SubAffiliate;
