import React from "react";
import {_t} from "../../Common/components/InjectIntlContext";
import CustomButton from "../../Common/components/CustomButton";
import {Typography} from "antd";
import BenefitCardItem from "./BenefitCardItem";
import config from "../../../system/config";

const slides = (_t: any) => [
    {
        title: _t("benefit_1"),
        icon: `${config.projectImages}/our-benefits/image_1.svg`,
    },
    {
        title: _t("benefit_2"),
        icon: `${config.projectImages}/our-benefits/image_2.svg`,
    },
    {
        title: _t("benefit_3"),
        icon: `${config.projectImages}/our-benefits/image_3.svg`,
    },
];

export type ourBenefitsProps = {
    getStarted: () => void;
};

const OurBenefits: React.FC<ourBenefitsProps> = ({getStarted}) => {
    const benefitCards = slides(_t);
    const benefit = (card, idx) => (
        <li className="benefits-grid__item" key={card.title + idx}>
            <BenefitCardItem info={card}/>
        </li>
    );

    return (
        <section className="our-benefits custom-section">
            <div className="container">
                <Typography.Title level={2} className="custom-section__title">
                    {_t("our_benefits")}
                </Typography.Title>
                <ul className="benefits-grid">{benefitCards.map(benefit)}</ul>
                <div className="get-started">
                    <CustomButton
                        onClick={getStarted}
                        btnLabel={_t("get_started")}
                        className=" btn--big"
                    />
                </div>
            </div>
        </section>
    );
};

export default OurBenefits;
